import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Calendar as CalendarIcon } from 'react-feather';

const options = ['Last 7 Days', 'Last 30 Days', 'This Month', 'Last 90 Days', 'This Year'];


const timeRanges = [
  {
    value: 'today',
    text: 'Today'
  },
  {
    value: 'yesterday',
    text: 'Yesterday'
  },
  {
    value: 'last_30_days',
    text: 'Last 30 days'
  },
  {
    value: 'last_year',
    text: 'Last year'
  }
];

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  // start & end date ranges
  const [selectEdge, setSelectEdge] = useState(null);
  const isCalendarOpen = Boolean(selectEdge);
  const handleCalendarOpen = edge => setSelectEdge(edge);
  const handleCalendarChange = date => {
    console.log(date)
    debugger
  };
  const handleCalendarClose = () => setSelectEdge(null);
  const handleCalendarAccept = date => {
    return selectEdge === 'start' ? rest.handleStartChange(date.toISOString()) : rest.handleEndChange(date.toISOString());
  }

  // time range
  const [anchorEl, setAnchorEl] = useState(null);
  const isTimeRangeOpen = Boolean(anchorEl);
  const handleTimeRangeClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleTimeRangeClose = () => setAnchorEl(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    rest.handleFilter(index)
  }

  const [timeRange, setTimeRange] = useState(timeRanges[2].text);
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify='space-between'
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            variant='body1'
            color='inherit'
            to='/app'
            component={RouterLink}
          >
            Dashboard
          </Link>
          {/* <Typography
            variant="body1"
            color="textPrimary"
          >
            Reports
          </Typography> */}
        </Breadcrumbs>
        <Typography
          variant='h3'
          color='textPrimary'
        >
          Finance Overview
        </Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant='contained'>
          <Button onClick={() => handleCalendarOpen('start')}>
            <CalendarTodayIcon className={classes.calendarTodayIcon} />
            {rest.startDate ? moment(rest.startDate).format('MMMM Do, YYYY') : ''}
          </Button>
          <Button onClick={() => handleCalendarOpen('end')}>
            <CalendarTodayIcon className={classes.calendarTodayIcon} />
            {rest.endDate ? moment(rest.endDate).format('MMMM Do, YYYY') : ''}
          </Button>
          <Button
            color='primary'
            size='small'
            onClick={handleTimeRangeClick}
          >
            <FilterListIcon />
          </Button>
        </ButtonGroup>
        <Popper  
          open={isTimeRangeOpen} 
          anchorEl={anchorEl}
          transition>
          {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <Paper id='menu-list-grow'>
                  <ClickAwayListener onClickAway={handleTimeRangeClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
            </Grow>
          )}
        </Popper>
        <DatePicker
          style={{ display: 'none' }} // Temporal fix to hide the input element
          variant='dialog'
          open={isCalendarOpen} 
          onAccept={handleCalendarAccept}
          onChange={handleCalendarChange}
          onClose={handleCalendarClose}          
          maxDate={moment()}
          value={moment()}
        />
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
