import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';

import axios from 'utils/axios';
import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

const Statistics = props => {
  const { className, data, invoice, ...rest } = props;
  const { user, invoiceItems } = data
  const formatCents = value => (value/100).toFixed(2)
  // const planItemAmount = invoiceItems.filter(i => i.description.indexOf("1 × Transcriptive") > -1)[0].amount
  const userCreated = new Date(user.createdAt).getTime()

  const due = data.invoiceItems.reduce(function (a, b) {
  return b['amount'] == null ? a : a + b['amount'];
  }, 0);

  const classes = useStyles();
  const [statistics, setStatistics] = useState(null);
  // show $ balance and minutes left
  let balance = 0;
  let minutesLeft = 0;
  if (props.data && props.data.user) {
    balance = props.data.user.balance || 0;
    minutesLeft = props.data.user.minutesLeft || 0;
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems='center'
        container
        justify='space-between'
      >

        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={12}
        >
          <div className={classes.titleWrapper}>
            <Typography
              component='span'
              variant='h2'
            >
              ${formatCents(balance)}
            </Typography>
          </div>
          <Typography
            className={classes.overline}
            variant='overline'
          >
            Cash Balance
          </Typography>
        </Grid>

        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={12}
        >
          <Typography variant='h2'>{minutesLeft}</Typography>
          <Typography
            className={classes.overline}
            variant='overline'
          >
            Minutes Balance
          </Typography>
        </Grid>

        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={12}
        >
          <div className={classes.titleWrapper}>
            <Typography
              component='span'
              variant='h2'
            >
              {/* ${user.planName.indexOf('Producer') > -1 && (new Date() - userCreated) / (1000 * 3600 * 24) < 30 ? `${formatCents(due - planItemAmount)}` : `${formatCents(due)}` } */}
              ${formatCents(invoice.amount_due)}
            </Typography>
          </div>
          <Typography
            className={classes.overline}
            variant='overline'
          >
            Upcoming Due
          </Typography>
        </Grid>

      </Grid>
    </Card>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
