import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Link
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProjectInfo = props => {
  const { project, className, ...rest } = props;

  const classes = useStyles();

  const options = ['Canceled', 'Completed', 'Rejected'];

  const [option, setOption] = useState(options[0]);

  const handleChange = event => {
    event.persist();

    setOption(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='Project info' />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/admin/customers/${project.userId._id}`}
                >
                  {project.userId && project.userId.firstname ? project.userId.firstname : '' }
                  {project.userId && project.userId.lastname ? project.userId.lastname : '' }
                </Link>
              </TableCell>
            </TableRow>


            <TableRow selected>
              <TableCell>Date</TableCell>
              <TableCell>
                {moment(project.createdAt).format('DD/MM/YYYY HH:MM')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{project._id}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Name</TableCell>
              <TableCell>
                {project.name}
              </TableCell>
            </TableRow>


            <TableRow>
              <TableCell>Bill Code</TableCell>
              <TableCell>{project.billCode}</TableCell>
            </TableRow>





          </TableBody>
        </Table>
      </CardContent>
      {/*
      <CardActions className={classes.actions}>
        <Button>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
        <Button>
          <ReceiptIcon className={classes.buttonIcon} />
          Resend invoice
        </Button>
      </CardActions>
      */}
    </Card>
  );
};

ProjectInfo.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default ProjectInfo;
