import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import TrustFormContainer from "./TrustFormContainer";
import { useModal } from "react-modal-hook";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {
    marginTop: theme.spacing(2)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

function SetTrust({ user, className, setUser, ...rest }) {
  // debugger
  console.log('SET TRUST')
  console.log(user)
  const classes = useStyles();
  // const [_user, setUser] = useState(user);

  const [showEditModal, hideEditModal] = useModal(({ in: open, onExited }) => (
    <TrustFormContainer
      // id={id}
      user={user}
      setUser={setUser}
      open={open}
      onExited={onExited}
      onClose={hideEditModal}
    />
  ));

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='Trust' />
      <Divider />
      <CardContent className={classes.content}>

        <Table className={classes.table}>
          <TableBody>
          <TableRow>
            <TableCell>Trust</TableCell>
            <TableCell>
              {user ? user.trust : ''}
            </TableCell>
          </TableRow>
          </TableBody>
        </Table>
        <Button
          className={classes.sendButton}
          variant='contained'
          onClick={showEditModal}
          color='primary'
        >
          <EditIcon className={classes.mailIcon} />
          Edit
        </Button>
      </CardContent>
    </Card>
  );
}

SetTrust.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default SetTrust;
