import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

// const getSum = (invoices, type) => {
//   const filtered = invoices.filter(invoice => invoice.type === type);
//   const total = filtered
//     .reduce((total, invoice) => total + invoice.value, 0)
//     .toFixed(2);
//
//   return [filtered, total];
// };

const getSum = (invoices) => {

  if(invoices) {
    const total = invoices
      .reduce((total, invoice) => total + invoice.amountPaid, 0);
      // .toFixed(2);
    return (total/100).toFixed(2);
    // return [filtered, total];
  } else {
    return 0
  }

};

const Invoices = props => {
  const {
    // customer,
    className, ...rest } = props;

  const classes = useStyles();

  const handleEditOpen = () => {};

  const total = getSum(rest.invoices);

  // const [paidInvoices, paidTotal] = getSum(rest.invoices, 'paid');
  // const [draftInvoices, draftTotal] = getSum(customer.invoices, 'draft');
  // const [dueInvoices, dueTotal] = getSum(customer.invoices, 'due');
  // const [refundedInvoices, refundedTotal] = getSum(
  //   customer.invoices,
  //   'refunded'
  // );
  // const [incomeInvoices, incomeTotal] = getSum(customer.invoices, 'income');

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='Invoices/Billing' />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Auto CC Pay</TableCell>
              <TableCell>
                {rest.paymentMethods.length && rest.paymentMethods[0].last4}
                {/*
                {customer.iban}
                <div>
                  <Label
                    color={
                      customer.autoCC ? colors.green[600] : colors.red[600]
                    }
                  >
                    {customer.autoCC ? 'YES' : 'NO'}
                  </Label>
                </div>
                */}
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Paid</TableCell>
              <TableCell>
                {rest.invoices && rest.invoices.length ? total : 0}
              </TableCell>
            </TableRow>

              {/*
            <TableRow>
              <TableCell>Auto CC Pay</TableCell>
              <TableCell>
                {customer.iban}
                <div>
                  <Label
                    color={
                      customer.autoCC ? colors.green[600] : colors.red[600]
                    }
                  >
                    {customer.autoCC ? 'YES' : 'NO'}
                  </Label>
                </div>
              </TableCell>
            </TableRow>

            <TableRow selected>
              <TableCell>Paid</TableCell>
              <TableCell>
                {paidInvoices.length} ({customer.currency}
                {paidTotal})
              </TableCell>
            </TableRow>
              */}
            <TableRow>
              <TableCell>Draft</TableCell>
              <TableCell>
                0
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Unpaid/Due</TableCell>
              <TableCell>
                0
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Refunded</TableCell>
              <TableCell>
                0
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Gross Income</TableCell>
              <TableCell>
                0
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </CardContent>
      {/*
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
        <Button>
          <AttachMoneyIcon className={classes.buttonIcon} />
          Create Invoice
        </Button>
        <Button>
          <ReceiptIcon className={classes.buttonIcon} />
          Generate Due Invoices
        </Button>
      </CardActions>
      */}
    </Card>
  );
};

Invoices.propTypes = {
  className: PropTypes.string,
  // customer: PropTypes.object.isRequired
};

export default Invoices;
