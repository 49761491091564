import React, { useState, useEffect, useContext } from 'react';
import axios from 'utils/axios';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';

import { apiConfig } from 'config';
import { Page } from 'components';
import {
  Header,
  General,
  Subscription,
  PaymentMethods,
  Notifications,
  Security
} from './components';

import AuthContext from 'context/auth/authContext';
import DashboardLayout from 'layouts/Dashboard'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Settings = props => {

  const authContext = useContext(AuthContext);
  const { login, clearErrors, isAuthenticated } = authContext;
  const { user } = authContext || {}
  const { loadUser } = authContext

  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;

  // const [user, setUser] = useState(null);

  const handleUpgrade = (planName) => {
    // const user = JSON.parse(localStorage.getItem('user'));
    const { _id: id } = user;

    axios.post(`/v1/subscription/update`, { id, planName }).then(response => {
      // localStorage.setItem('user', response.data.subscription.userId)
      // setUser(response.data.subscription.userId);
      loadUser()
    });
  }

  const handleCancel = () => {
    axios.post(`/v1/subscription/cancel`).then(response => {
      localStorage.removeItem('token');
      // localStorage.removeItem('user');
      history.push('/admin/login');
    });
  }

  // useEffect(() => {
  //   let mounted = true;
  //
  //   const fetchClient = () => {
  //     // const user = JSON.parse(localStorage.getItem('user'));
  //     const { id } = user;
  //
  //     axios.get(`/v1/auth/client/${id}`).then(response => {
  //       if (mounted) {
  //        setUser(response.data.user);
  //       }
  //     });
  //   };
  //
  //   fetchClient();
  //
  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  const handleFilter = () => {};
  const handleSearch = () => {};

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'general', label: 'General' },
    { value: 'subscription', label: 'Subscription' },
    { value: 'card', label: 'Card' },
  ];

  if (!tab) {
    return <Redirect to='/settings/general' />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to='/errors/error-404' />;
  }

  return (
    <DashboardLayout>
    <Page
      className={classes.root}
      title='Settings'
    >
      <Header />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'general' && <General user={user} />}
        {tab === 'subscription' && <Subscription user={user} onUpgrade={handleUpgrade} onCancel={handleCancel} history={history} />}
        {tab === 'card' && <PaymentMethods user={user} />}
        {tab === 'security' && <Security user={user} />}
      </div>
    </Page>
    </DashboardLayout>
  );
};

Settings.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Settings;
