import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import MomentUtils from '@date-io/moment';

import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {StripeProvider} from 'react-stripe-elements';
import { ModalProvider, useModal } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";
import AuthProvider from 'context/auth/AuthProvider';

import DashboardLayout from './layouts/Dashboard';

import Dashboard from './views/Dashboard';
import Users from './views/Users/Users';
import UserDetails from './views/UserDetails/UserDetails';
import Login from './views/Login/Login';
import Register from './views/Register/Register';
import Overview from './views/Overview/Overview';
import Projects from './views/Projects/Projects';
import ProjectDetails from './views/ProjectDetails/ProjectDetails';
import Jobs from './views/Jobs/Jobs';
import JobDetails from './views/JobDetails/JobDetails';
import Invoices from './views/Invoices/Invoices';
import InvoiceDetails from './views/InvoiceDetails/InvoiceDetails';
import ChargeDetails from './views/ChargeDetails/ChargeDetails';
import Settings from './views/Settings/Settings';

import theme from './theme';
// import './mixins/chartjs';
// import './mixins/moment';
import './mixins/validate';
// import './mixins/prismjs';
import '../assets/scss/index.scss';

const history = createBrowserHistory();
import { apiConfig, isProd, isWeb,stripeLivePublishableKey, stripeTestPublishableKey } from './config';
import routes from './routes';



const stripePublishableKey =
  isProd &&
  isWeb &&
  ['app.transcriptive.com', 'beta.transcriptive.com'].includes(window.location.host)
    ? stripeLivePublishableKey
    : stripeTestPublishableKey

import PrivateRoute from 'components/Routing/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <StripeProvider apiKey={stripePublishableKey}>
        <ModalProvider container={TransitionGroup}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Router>
                    <Switch>
                      <Redirect exact from='/' to='/admin/login' />
                      <Redirect exact from='/admin' to='/admin/login' />
                      <Route exact path='/admin/login' component={Login} />
                      <Route exact path='/admin/register' component={Register} />
                      <PrivateRoute exact path='/admin/overview' component={Overview} />
                      <PrivateRoute exact path='/admin/dashboard' component={Dashboard} />
                      <PrivateRoute exact path='/admin/users' component={Users} />
                      <PrivateRoute exact path='/admin/users/:id' component={UserDetails} />
                      <PrivateRoute exact path='/admin/users/:id/:tab' component={UserDetails} />
                      <PrivateRoute exact path='/admin/projects' component={Projects} />
                      <PrivateRoute exact path='/admin/projects/:id' component={ProjectDetails} />
                      <PrivateRoute exact path='/admin/jobs' component={Jobs} />
                      <PrivateRoute exact path='/admin/jobs/:id' component={JobDetails} />
                      <PrivateRoute exact path='/admin/invoices' component={Invoices} />
                      <PrivateRoute exact path='/admin/invoices/:id' component={InvoiceDetails} />
                      <PrivateRoute exact path='/admin/charges/:id' component={ChargeDetails} />
                      <PrivateRoute exact path='/admin/settings' component={Settings} />
                      <PrivateRoute exact path='/admin/settings/:tab' component={Settings} />
                    </Switch>
                  </Router>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
        </ModalProvider>
      </StripeProvider>
    </AuthProvider>
  );
};

export default App;
