import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';

import axios from 'utils/axios';
import { Page } from 'components';
import { Header, Details } from './components';

import DashboardLayout from 'layouts/Dashboard';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));




const ChargeDetails = props => {
  const { match, history } = props;
  const { id } = match.params;
  const classes = useStyles();
  const [charge, setCharge] = useState(null);
  const [transactions, setTransactions] = useState(null);


  const addTransaction = txn => {
    setTransactions([...transactions, txn])
  }

  useEffect(() => {
    let mounted = true;

    const fetchCharge = (id) => {
      axios.get(`/v1/charge/${id}`).then(response => {
        const { data } = response;
        if (mounted) {
          setCharge(data.charge)
          setTransactions(data.transactions)
        }
      });
    };

    fetchCharge(id);

    return () => {
      mounted = false;
    };
  }, []);

  // if (!charge && !transactions) {
  //   return null;
  // }

  if (!charge ) {
    return null;
  }

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='Charge Details'
      >
        <Header charge={charge} addTransaction={addTransaction} setTransactions={setTransactions} transactions={transactions} />
        <Divider className={classes.divider} />
        <Details charge={charge} transactions={transactions} />
      </Page>
    </DashboardLayout>
  );
};

export default ChargeDetails;
