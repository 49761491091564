import React, { useState, useEffect, useContext } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import AuthContext from 'context/auth/authContext';
import useRouter from 'utils/useRouter';

import axios from 'axios';

import AddCardForm from 'components/PrepaidModal/AddCardForm'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

const LoginForm = props => {
  const [data, setData] = useState(initialState);
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [open, setOpen] = React.useState(false);
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const apiError = authContext.error;
  const { login, clearErrors, isAuthenticated, user, userError } = authContext;
  useEffect(() => {
    if (isAuthenticated && user) {
      const { role } = user;
      if (role === 'user') {
        router.history.push(`/admin/overview${props.redirectMinutes ? `?redirectMinutes=${props.redirectMinutes}`: ''}`);
      } else {
        router.history.push('/admin/dashboard');
      }
    }
    if (authContext.error) {
      setOpen(true);
    }
  }, [error, isAuthenticated, authContext]);

  const { className, ...rest } = props;

  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null
  };
  
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setOpen(false);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    login(formState.values)

    // axios
    //   .post('/v1/auth/login', formState.values)
    //   .then(response => {
    //     const { data } = response;
    //     if(data.error) {
    //       setError(data.detail);
    //       setOpen(true);
    //     } else {
    //       // dispatch({
    //       //   type: "LOGIN",
    //       //   payload: response.data
    //       // });
    //
    //       login(response.data)
    //
    //       if (response.data.user.role === "user") {
    //         router.history.push('/admin/overview');
    //       } else {
    //         router.history.push('/admin/dashboard');
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   });
  };

  const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;

  const cardUrl = '/v1/card';

  const addCard = data => {
    // setShowLoading(true);
    data.userId = user._id;
    axios.post(cardUrl, data)
      .then((result) => {
        clearErrors()
        setMsg('Card successfully added! Please try logging in again.');
        if (result.data.error && result.data.error.match(/No such token/)) {
          setMsg('Error adding new card, please try again');
        }
  	// const { card } = result.data;
	      // setCards([...cards, card]);
        // setShowLoading(false);
        // setSnackMessage(msg)
        // setOpen(true);
      })
      // .catch((error) => setShowLoading(false));
  }



  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >

    {(error || apiError) &&
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MySnackbarContentWrapper
            variant='error'
            className={classes.margin}
            message={error || apiError}
            onClose={handleClose}
          />
        </Snackbar>
      }

    {(msg) &&
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MySnackbarContentWrapper
            variant='error'
            className={classes.margin}
            message={msg}
            onClose={handleClose}
          />
        </Snackbar>
      }

      {userError ? 
        <AddCardForm addCard={addCard} buttonText='Save' /> : 
        <>
        <div className={classes.fields}>
          <TextField
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') ? formState.errors.email[0] : null}
            label='Email address'
            name='email'
            onChange={handleChange}
            value={formState.values.email || ''}
            variant='outlined'
          />
          <TextField
            error={hasError('password')}
            fullWidth
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label='Password'
            name='password'
            onChange={handleChange}
            type='password'
            value={formState.values.password || ''}
            variant='outlined'
          />
        </div>
        <Button
          className={classes.submitButton}
          color='secondary'
          disabled={!formState.isValid}
          size='large'
          type='submit'
          variant='contained'
      >
      Sign In
      </Button>
        </>
      }
      
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

export default LoginForm;
