/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import NotesIcon from '@material-ui/icons/Notes';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import {
  Folder as FolderIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import AuthContext from 'context/auth/authContext';

let navConfig = [
  {
    items: [
      {
        title: 'Home',
        href: '/admin/overview',
        icon: HomeIcon
      },
      {
        title: 'Home',
        href: '/admin/dashboard',
        icon: HomeIcon
      },
      {
        title: 'Users',
        href: '/admin/users',
        icon: UsersIcon
      },
      {
        title: 'Web Items',
        href: '/admin/projects',
        icon: FolderIcon
      },
      {
        title: 'Jobs',
        href: '/admin/jobs',
        icon: NotesIcon
      },
      {
        title: 'Invoices',
        href: '/admin/invoices',
        icon: ReceiptIcon
      },
      {
        title: 'Settings',
        icon: SettingsIcon,
        href: '/admin/settings',
        items: [
          {
            title: 'General',
            href: '/admin/settings/general'
          },
          {
            title: 'Subscription',
            href: '/admin/settings/subscription'
          },
          {
            title: 'Card',
            href: '/admin/settings/card'
          },
        ]
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo : {
    color: '#ffffff'
  },
}));

function NavBar({ openMobile, onMobileClose, }) {

  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, loadUser, user } = authContext;
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!user) {
    return null
  } else {
    if (user.role === 'user') {
      navConfig[0].items = navConfig[0].items.filter(i => i.href !== "/admin/dashboard" && i.href !== "/admin/users");      
    } else {
      navConfig[0].items = navConfig[0].items.filter(i => i.href !== "/admin/overview");      
    }
  }

  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display='flex'
            justifyContent='center'
          >
            <RouterLink to='/'>
              {/*<Logo />*/}
              <Typography 
                variant='h4' 
                className={classes.logo}
              >
                Transcriptive
              </Typography>
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display='flex'
            justifyContent='center'
          >
            <RouterLink to='/admin/settings/general'>
              {/*
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
              */}
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign='center'
          >
            <Link
              component={RouterLink}
              to='/admin/settings/general'
              variant='h5'
              color='textPrimary'
              underline='none'
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography
              variant='body2'
              color='textSecondary'
            >
              {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
