import React, { useState, useEffect, Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CardTable from './tables/CardTable'
import axios from 'utils/axios';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

import AuthContext from 'context/auth/authContext';

const Subscription = props => {

  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, loadUser } = authContext;

  const { className, user, ...rest } = props;
  const classes = useStyles();

  const [ prices, setPrices] = useState([])
  const [showLoading, setShowLoading] = useState(true);
  const apiUrlPrice = '/v1/price';
  const apiUrlCard = '/v1/card';

  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState('bottom');
  const [horizontal, setHorizontal] = useState('center');
  const [snackMessage, setSnackMessage] = useState('');
  const [ cards, setCards ] = useState([])
  
  useEffect(() => {
    let mounted = true;
    const fetchCards = () => {
      axios.get(apiUrlCard).then(response => {
        if (mounted) {
          setCards(response.data.cards);
          setShowLoading(false)
        }
      });
    };
    fetchCards();

    const fetchPrices = () => {
      axios.get(apiUrlPrice).then(response => {
        if (mounted) {
          setPrices(response.data);
          setShowLoading(false)
        }
      });
    };
    fetchPrices();
    return () => {
      mounted = false;
    };
  }, []);

  const changePlan = data => {
    setShowLoading(true);
    axios.post('/v1/subscription/changePlan', data)
      .then((result) => {
        loadUser()
        const { user } = result.data;
        setShowLoading(false);
        setSnackMessage('Plan changed successfully!')
        setOpen(true);
      })
      .catch((error) => {
        const { data } = error.response
        setShowLoading(false)
        setSnackMessage(data)
        setOpen(true);
      });
  }

  const handleClose = () => { setOpen(false) }

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
        >
        <CardHeader
          classes={{ action: classes.action }}
          title='Plan and Pricing'
          />
        <Divider />
        <CardContent>
          <Card>
            {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            <CardContent className={classes.details}>
               <CardTable
                 user={user}
                 data={prices}
                 changePlan={changePlan}
                 hasCard={cards && cards.length > 0}
                 history={props.history}
               />
            </CardContent>
            }
          </Card>
        </CardContent>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={open}
          onClose={handleClose}
          ContentProps={{
          'aria-describedby': 'message-id',
          }}
          autoHideDuration={2000}
          transitionDuration={500}
          message={<span id='message-id'>{snackMessage}</span>}
          action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
            >
            <CloseIcon />
          </IconButton>,
          ]}
          />
      </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;
