import React , { useState, useEffect } from 'react';
// import axios from 'utils/axios';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { Container, CircularProgress, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';

import moment from 'moment';

import { Page } from 'components';

import DashboardLayout  from 'layouts/Dashboard'

import {
  Header,
  StatsCard,
  FinancialStats,
  EarningsSegmentation,
  TopReferrals,
  MostProfitableProducts,
  CustomerActivity,
  LatestOrders
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    '& > *': {
      height: '100%'
    }
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const today = moment(new Date());
  let todayMonth = parseInt(today.format('M'));
  let todayDay = parseInt(today.format('D'));
  const todayYear = parseInt(today.format('YYYY'));

  const sevenDaysAgo = moment(new Date()).add(-7, 'days');
  let sevenDaysAgoMonth = parseInt(sevenDaysAgo.format('M'));
  const sevenDaysAgoMonthDateParam = sevenDaysAgoMonth - 1;
  let sevenDaysAgoDay = parseInt(sevenDaysAgo.format('D'));
  const sevenDaysAgoYear = parseInt(sevenDaysAgo.format('YYYY'));

  const [start, setStart] = useState(moment(new Date()).subtract(30, 'days').format());
  const [end, setEnd] = useState(moment(new Date()).format());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [type, setType] = useState(0)
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState('createdAt')

  const [totalPayments, setTotalPayments] = useState(0.00);
  const [totalRefunds, setTotalRefunds] = useState(0.00);
  const [totalCosts, setTotalCosts] = useState(0.00);
  const [subscriptions, setSubscriptions] = useState(0.00);
  const [segments, setSegments] = useState([]);

  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [header, setHeader] = useState("30 Days Ago");
  useEffect(() => {
    let mounted = true;

    const fetchAnalytics = () => {

      axios.get(`/v1/analytics?type=${type}`).then(response => {
        const {
          successChargesData,
          successChargesLabels,
          failedChargesData,
          failedChargesLabels,
          refundedChargesData,
          refundedChargesLabels,


          paymentsData,
          paymentsLabels,
          refundsData,
          serviceCostsData,
          start,
          end,
          startDate,
          endDate,
          subscriptions,
          serviceSegment
        } = response.data;

        if (mounted) {
          setTotalPayments(paymentsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
          // setTotalPayments(successChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
          setTotalRefunds(refundsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
          // setTotalRefunds(refundedChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
          setTotalCosts(serviceCostsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
          setSubscriptions(subscriptions);

          setData({ paymentsData, refundsData, serviceCostsData});
          // setData({ paymentsData:successChargesData, refundsData:refundedChargesData, serviceCostsData});
          setLabels(paymentsLabels)
          setStartDate(start)
          setEndDate(end)
          setSegments(serviceSegment)
        }
      });
    };

    fetchAnalytics();

    return () => {
      mounted = false;
    };
  }, []);

  const handleStartChange = (date) => {
    setStartDate(date)
    setType('')

    axios
      .get(`/v1/analytics?start=${encodeURIComponent(date)}&end=${encodeURIComponent(moment(end).toISOString())}`)
      .then(response => {
        const {
          successChargesData,
          successChargesLabels,
          failedChargesData,
          failedChargesLabels,
          refundedChargesData,
          refundedChargesLabels,

          paymentsData,
          paymentsLabels,
          refundsData,
          serviceCostsData,
          start,
          end,
          subscriptions,
          serviceSegment
        } = response.data;

        setTotalPayments(paymentsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalPayments(successChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalRefunds(refundsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalRefunds(refundedChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalCosts(serviceCostsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        setSubscriptions(subscriptions);

        setData({ paymentsData, refundsData, serviceCostsData});
        // setData({ paymentsData:successChargesData, refundsData:refundedChargesData, serviceCostsData});
        setLabels(paymentsLabels)
        setStartDate(start)
        setEndDate(end)
        setSegments(serviceSegment)
      });
  }

  const handleEndChange = (date) => {

    setEndDate(date)
    setType('')

    axios
      .get(`/v1/analytics?start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(moment(date).toISOString())}`)
      .then(response => {
      const {
        successChargesData,
        successChargesLabels,
        failedChargesData,
        failedChargesLabels,
        refundedChargesData,
        refundedChargesLabels,

        paymentsData,
        paymentsLabels,
        refundsData,
        serviceCostsData,
        start,
        end,
        subscriptions,
        serviceSegment
      } = response.data;

        setTotalPayments(paymentsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalPayments(successChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalRefunds(refundsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalRefunds(refundedChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalCosts(serviceCostsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        setSubscriptions(subscriptions);

        setData({ paymentsData, refundsData, serviceCostsData});
        // setData({ paymentsData:successChargesData, refundsData:refundedChargesData, serviceCostsData});
        setLabels(paymentsLabels)
        setStartDate(start)
        setEndDate(end)
        setSegments(serviceSegment)
    });
  }
  const handleFilter = (index) => {
    setType(index)

    axios
      .get(`/v1/analytics?type=${index}`)
      .then(response => {
        const {
          successChargesData,
          successChargesLabels,
          failedChargesData,
          failedChargesLabels,
          refundedChargesData,
          refundedChargesLabels,
          
          paymentsData,
          paymentsLabels,
          refundsData,
          serviceCostsData,
          start,
          end,
          subscriptions,
          serviceSegment
        } = response.data;

        setTotalPayments(paymentsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalPayments(successChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalRefunds(refundsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        // setTotalRefunds(refundedChargesData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00))
        setTotalCosts(serviceCostsData.reduce(function(acc, val) { return acc + parseFloat(val); }, 0.00).toFixed(2))
        setSubscriptions(subscriptions);

        setData({ paymentsData, refundsData, serviceCostsData});
        // setData({ paymentsData:successChargesData, refundsData:refundedChargesData, serviceCostsData});
        setLabels(paymentsLabels)
        setStartDate(start)
        setEndDate(end)
        setSegments(serviceSegment)

        let header;
        switch(index) {
          case 100:
            header = "30 Days Ago";
            break;
          case 200:
            header = "3 Months Ago";
            break;
          case 300:
            header = "6 Months Ago";
            break;
          case 400:
            header = "12 Months Ago";
            break;
        }

        setHeader(header)
      }
    );
  };

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='Analytics Dashboard'
      >
        <Container maxWidth={false}>
          <Header 
            startDate={startDate}
            endDate={endDate}
            sortOrder={sortOrder}
            sortField={sortField}
            handleFilter={handleFilter}
            subscriptions={subscriptions}
            handleStartChange={handleStartChange}
            handleEndChange={handleEndChange}
          />
          <Grid
            container
            spacing={3}
            style={{textAlign: "center"}}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <StatsCard
                title='Payments'
                text={`$${totalPayments}`}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <StatsCard
                title='Refunds'
                text={`$${totalRefunds}`}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <StatsCard
                title='Service Costs'
                text={`$${totalCosts}`}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <StatsCard
                title='Subscriptions'
                text={`${subscriptions}`}
              />
            </Grid>

            <Grid
              item
              lg={12}
              xl={12}
              xs={12}
              style={{textAlign: "center"}}
            >

              {!isEmpty(data) ? <FinancialStats data={data} labels={labels} handleFilter={()=>{}} />
                : <CircularProgress className={classes.progress} />
              }
            </Grid>
          </Grid>

        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default Dashboard;
