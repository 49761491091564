import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import axios from 'utils/axios';
import { Page } from 'components';
import { Header, ProjectInfo } from './components';
import DashboardLayout from 'layouts/Dashboard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const ProjectDetails = props => {
  const { match, history } = props;
  const { id } = match.params;

  const classes = useStyles();
  const [project, setProject] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchProject = (id) => {
      axios
        .get(`/v1/project/${id}`)
        .then(response => {
          const { data } = response;
          if (mounted) {
            setProject(data[0])
          }
        });
    };

    fetchProject(id);

    return () => {
      mounted = false;
    };
  }, []);

  if (!project) {
    return null;
  }

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='Project Details'
      >
        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xl={3}
            xs={12}
          >
            <ProjectInfo project={project} />
          </Grid>
        </Grid>
      </Page>
    </DashboardLayout>
  );
};

export default ProjectDetails;
