import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Link,
  Paper,
  Radio,
  Typography,
  colors
} from '@material-ui/core';
import axios from 'utils/axios';

// import { AuthContext } from "../../App";
// import  AuthContext  from 'context/AuthContext';


import freelancer_logo from '../../../assets/images/products/product_freelancer.svg'
import agency_logo from '../../../assets/images/products/product_agency--outlined.svg'
import enterprise_logo from '../../../assets/images/products/product_enterprise.svg'

const useStyles = makeStyles(theme => ({
  root: {
    width: 960
  },
  header: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  content: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    maxWidth: 720,
    margin: '0 auto'
  },
  product: {
    overflow: 'unset',
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  options: {
    lineHeight: '26px'
  },
  recommended: {
    backgroundColor: theme.palette.primary.main,
    '& *': {
      color: `${theme.palette.white} !important`
    }
  },
  contact: {
    margin: theme.spacing(3, 0)
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  startButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  form: {
    marginTop: theme.spacing(3),
  },
  option: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    maxWidth: 560,
    '& + &': {
      marginTop: theme.spacing(2)
    }
  },
  selectedOption: {
    backgroundColor: colors.grey[50]
  },
  optionRadio: {
    margin: -10
  },
  optionDetails: {
    marginLeft: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = [
  // {
  //   value: '500',
  //   title: '$5'
  // },
  // {
  //   value: '1000',
  //   title: '$10'
  // },
  {
    value: '15000',
    title: '$150 (62.5 hours of transcription at $0.04/min)'
  },
  {
    value: '50000',
    title: '$500 (208 hours of transcription at $0.04/min)'
  }
];


import AddCardForm from './AddCardForm'


const apiUrl = '/v1/card';

import AuthContext from 'context/auth/authContext';


const PrepaidModal = props => {
  const authContext = useContext(AuthContext);
  const { login, clearErrors, isAuthenticated, loadUser } = authContext;

  const { open, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState(options[0].value);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const { dispatch } = React.useContext(AuthContext);

  const handleChange = (event, option) => {
    setSelected(option.value);
  };

  const addCard = data => {
    // console.log(data)
    // console.log(selected)
    setLoading(true);
    setError(null);
		axios.post(`${apiUrl}/prepaid`, {...data, amount: parseInt(selected)})
		 .then((result) => {
       const { ending_balance } = result.data.result
      //  dispatch({
      //     type: "UPDATE",
      //     payload: { balance: ending_balance }
      // })
       onClose()
       loadUser()
			 // const { card } = result.data;
			 // setCards([...cards, card]);
       setLoading(false);
       // setSnackMessage('Card successfully added!')
       // setOpen(true);
     })
     .catch(error => {
        setLoading(false);  
        setError(error.response.data.error);
     });
	}

  return (
    <Dialog
      maxWidth='lg'
      onClose={onClose}
      open={open}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <Typography
            align='center'
            gutterBottom
            variant='h3'
          >
            Boost your credit balance and receive a discount!
          </Typography>
          <Typography
            align='center'
            className={classes.subtitle}
            variant='subtitle2'
          >
            All Transcriptive jobs will cost 1/2 less if they are paid with a credit balance.
          </Typography>
        </div>
        <div className={classes.content}>

          <Card
            {...rest}
            // className={clsx(classes.root, className)}
          >
            {/*<CardHeader title="Who are you exactly?" />*/}
            <CardContent>
              {options.map((option) => (
                <div
                  className={clsx(classes.option, {
                    [classes.selectedOption]: selected === option.value
                  })}
                  key={option.value}
                >
                  <Radio
                    checked={selected === option.value}
                    className={classes.optionRadio}
                    color='primary'
                    onClick={(event) => handleChange(event, option)}
                  />
                  <div className={classes.optionDetails}>
                    <Typography
                      gutterBottom
                      variant='h5'
                    >
                      {option.title}
                    </Typography>
                    {/*
                    <Typography variant="body1">{option.description}</Typography>
                    */}
                  </div>
                </div>
              ))}

              {isLoading ? (
                <div className={classes.progressWrapper}>
                  <CircularProgress className={classes.progress} />
                </div>
              ) : (
                <div className={classes.form}>
                  {error ? <div className='error' role='alert'>{`${error} Please enter a new card or try again.`}</div> : ''}
                  <AddCardForm  addCard={addCard} />
                </div>
              )}

            </CardContent>
          </Card>
        </div>

        {/*
        <div className={classes.actions}>
          <Button
            className={classes.startButton}
            onClick={onClose}
            variant='contained'
          >
            Confirm Purchase
          </Button>
        </div>
        */}
      </div>
    </Dialog>
  );
};

PrepaidModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default PrepaidModal;
