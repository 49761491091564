import React, { useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


import axios from 'utils/axios';
import useForm from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));


const DiscountFormContainer = ({
  user,
  setUser,
  fetchUser,
  coupon,
  _type,
  setSnackbarOpen,
  onClose,
  ...rest
}) => {
  console.log('DiscountFormContainer')


  const { register, handleSubmit, errors, getValues, setValue, formState, triggerValidation } = useForm();
  // if (coupon) {
  //   let { amount_off, duration, duration_in_months} = coupon
  // }
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(false);

  const [amount_off, setAmountOff] = useState(coupon ? (coupon.amount_off/100).toFixed(2) : '');
  const [type, setType] = useState(_type ? _type : '');
  const [duration, setDuration] = useState(coupon ? coupon.duration : '');
  const [duration_in_months, setDurationInMonths] = useState(coupon ? coupon.duration_in_months : '');
  const [showMonths, setShowMonths] = useState(duration_in_months ? true : false);
  const handleDurationInMonthsChange = (event) => {
    event.persist();
    setDurationInMonths(event.target.value);
  };
  
  const handleTypeChange = (event) => {
    event.persist();
    setType(event.target.value);
  };

  const handleDurationChange = (event) => {
    event.persist();
    setDuration(event.target.value);
    if(event.target.value === 'repeating') {
      setShowMonths(true)
    } else {
      setShowMonths(false)
    }
  };

  const handleAmountOffChange = (event) => {
    event.persist();
    setAmountOff(event.target.value);
  };


  const handleAddConfirm = async () => {
    try {
      setShowLoading(true);
      // if (remote_customer.discount) {
      //   axios.put(`/v1/coupon`, { user, type, amount_off, duration, duration_in_months }).then(response => {
      //     setShowLoading(false);
      //   });
      // } else {
      //   axios.post(`/v1/coupon`, { user, type, amount_off, duration, duration_in_months }).then(response => {
      //     setShowLoading(false);
      //   });
      // }
      await axios.post(`/v1/coupon`, { user, type, amount_off, duration, duration_in_months })
      await fetchUser(user._id)
      setShowLoading(false);
      onClose();

    } catch (error) {
      console.log(error)
    }
  };

  // useEffect(() => {
  //   console.log('coupon changed!')
  //   console.log({
  //     coupon,
  //   _type,
  //   })
  //   // debugger
  // }, [user]);

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      aria-labelledby='trust-dialog-slide-title'
    >
      <form className={classes.container} onSubmit={handleSubmit(handleAddConfirm)}>
        <DialogTitle id='trust-dialog-slide-title'>
          Edit Discount
        </DialogTitle>
        <DialogContent>
          {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            (<>
              <TextField
                fullWidth
                label='Amount'
                name='amount_off'
                onChange={handleAmountOffChange}
                value={amount_off}
                variant='outlined'
              />
              
              <div>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>Duration</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={duration}
                  onChange={handleDurationChange}
                >
                  <MenuItem value={'once'}>Once</MenuItem>
                  <MenuItem value={'forever'}>Forever</MenuItem>
                  <MenuItem value={'repeating'}>Repeating</MenuItem>
                </Select>
              </FormControl>

              {showMonths ? 
                <TextField
                  fullWidth
                  label='Duration Months'
                  name='duration_in_months'
                  onChange={handleDurationInMonthsChange}
                  value={duration_in_months}
                  variant='outlined'
                />
              :''}
              </div>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>Type</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={type}
                  onChange={handleTypeChange}
                >
                  <MenuItem value={'invoice'}>Invoice</MenuItem>
                  <MenuItem value={'subscription'}>Subscription</MenuItem>
                </Select>
              </FormControl>
            </>)
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            type='submit' 
            color='primary' 
            // disabled={!formState.isValid}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DiscountFormContainer;
