import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import Confirm from "./Confirm"

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography
} from '@material-ui/core';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        border: '1px solid #aab7c4',
        fontSize,
        color: '#32325d',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class StripeCardForm extends React.Component {
  state = {
    errorMessage: '',
    valid: false
  };

  handleChange = (evt) => {
    const { error, complete } = evt;
    if ((error || typeof error === 'undefined') && !complete) {
      const { message } = error || ''
      this.setState({errorMessage:message, valid: false});
    } else  {
      this.setState({valid: true});
    }
  };

  handleSubmit = async (ev) => {
    const {token, error } = await this.props.stripe.createToken({type: 'card' })
    if(token){
      this._element.clear()
      this.props.addCard({ token })

    }
  };

  render() {
    return (
      <Confirm title='Confirm' description='Are you sure?'>
        {confirm => (

          <form>
            <div style={{ border: '1px solid #aab7c4', borderRadius: '.25em', padding: '1em', marginBottom: '1em' }}>
              <CardElement
                onReady={(element) => this._element = element}
                onChange={this.handleChange}
                {...createOptions(this.props.fontSize)} />
            </div>



            <div className='error' role='alert'>
              {this.state.errorMessage}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Button
                size='small'
                variant='contained'
                color='primary'
                type='submit'
                onClick={confirm(this.handleSubmit)}
                disabled={!this.state.valid}
              >
                {this.props.buttonText ? this.props.buttonText : 'Confirm Purchase'}
              </Button>
            </div>
          </form>
        )}
      </Confirm>
    );
  }
}

export default injectStripe(StripeCardForm);
