import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import DiscountFormContainer from "./DiscountFormContainer";
import ClearFormContainer from "./ClearFormContainer";
import { useModal } from "react-modal-hook";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  deleteButton: {
    marginTop: theme.spacing(2),
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

function SetDiscount({ user, subscription, remote_customer, className, fetchUser, ...rest }) {
  const classes = useStyles();
  const [_user, setUser] = useState(user);

  if (!user) {
    return null;
  }
  let name = '';
  let type = '';
  let coupon;
  if (user.remote_customer && user.remote_customer.discount && user.remote_customer.discount.coupon) {
    coupon  = user.remote_customer.discount.coupon;
    type = 'invoice';
  }
  else if (user.remote_subscription && user.remote_subscription.discount && user.remote_subscription.discount.coupon) {
    coupon  = user.remote_subscription.discount.coupon;
    type = 'subscription';
  } else if (user.remote_subscription && user.remote_subscription.coupon) {
    coupon  = user.remote_subscription.coupon;
    type = 'subscription';
  }

  const [showEditModal, hideEditModal] = useModal(({ in: open, onExited }) => (
    <DiscountFormContainer
      user={user}
      coupon={coupon}
      _type={type}
      setUser={setUser}
      fetchUser={fetchUser}
      open={open}
      onExited={onExited}
      onClose={hideEditModal}
    />
  ), [coupon, type]);

  const [showClearModal, hideClearModal] = useModal(({ in: open, onExited }) => (
    <ClearFormContainer
      user={_user}
      _type={type}
      setUser={setUser}
      fetchUser={fetchUser}
      remote_customer={user.remote_customer}
      open={open}
      onExited={onExited}
      onClose={hideClearModal}
    />
    ), [type]);

  // useEffect(() => {
  //   console.log('coupon changed!')
  //   console.log({
  //     coupon,
  //     type,
  //   })
  //   // debugger
  // }, [coupon, type]);




  const { amount_off, percent_off, duration, duration_in_months } = coupon || '';

  if (amount_off) {
    name += `$${(amount_off/100).toFixed(2)}`;
  } else if (percent_off) {
    name += `${percent_off}%`;
  }

  if (type) {
    name += ` off ${type} `;
  }

  if (duration === 'forever' || duration === 'once') {
    name += duration;
  } else if (duration_in_months) {
    name += `for ${duration_in_months} months`;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='Discount' />
      <Divider />
      <CardContent className={classes.content}>

        <Table className={classes.table}>
          <TableBody>
          <TableRow>
            <TableCell>Discount</TableCell>
            <TableCell>
              {name ? name : 'None'}
            </TableCell>
          </TableRow>
          </TableBody>
        </Table>

        <Button
          className={classes.sendButton}
          variant='contained'
          onClick={showEditModal}
          color='primary'
        >
          <EditIcon className={classes.mailIcon} />
          Edit
        </Button>

        <Button
          className={classes.deleteButton}
          variant='contained'
          onClick={showClearModal}
          disabled={!coupon}
        >
          Delete
        </Button>

      </CardContent>
    </Card>
  );
}

SetDiscount.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default SetDiscount;
