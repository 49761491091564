import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import RefundFormContainer from "./RefundFormContainer";
import RetryFormContainer from "./RetryFormContainer";
import { useModal } from "react-modal-hook";
import AuthContext from 'context/auth/authContext';

const useStyles = makeStyles(theme => ({
  root: {},
  getAppIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Header = props => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { invoice, className, setTransactions, addTransaction, transactions, ...rest } = props;
  const [_invoice, setInvoice] = useState(invoice);
  const classes = useStyles();
  const [showEditModal, hideEditModal] = useModal(({ in: open, onExited }) => (
    <RefundFormContainer
      setInvoice={setInvoice}
      setTransactions={setTransactions}
      addTransaction={addTransaction}
      transactions={transactions}
      invoice={_invoice}
      open={open}
      onExited={onExited}
      onClose={hideEditModal}
    />
  ));

  const [showRetryModal, hideRetryModal] = useModal(({ in: open, onExited }) => (
    <RetryFormContainer
      setInvoice={setInvoice}
      setTransactions={setTransactions}
      addTransaction={addTransaction}
      transactions={transactions}
      invoice={_invoice}
      open={open}
      onExited={onExited}
      onClose={hideRetryModal}
    />
  ));


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems='flex-end'
        container
        justify='space-between'
        spacing={3}
      >
        <Grid item>
          <Typography
            component='h2'
            gutterBottom
            variant='overline'
          >
            Back
          </Typography>
          <Typography
            component='h1'
            variant='h3'
          >
            Invoice #{invoice.index}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={() => window.open(invoice.url, '_blank')}
          >
            <GetAppIcon className={classes.getAppIcon} />
            Download PDF
          </Button>
          {user && user.role === 'admin' &&
            <>
              <Button
                color='default'
                variant='contained'
                className={classes.button}
                onClick={showEditModal}
              >
                <AttachMoneyIcon className={classes.getAppIcon} />
                Refund
              </Button>

              <Button
                color='default'
                variant='contained'
                className={classes.button}
                onClick={showRetryModal}
                disabled={invoice.status !== 'failed'}
              >
                Retry Charge
              </Button>
            </>
          }
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Header;
