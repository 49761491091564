import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'serviceName', numeric: false, disablePadding: true, label: 'Service' },
  { id: 'cost', numeric: false, disablePadding: true, label: 'Cost' },
  { id: 'creditsUsed', numeric: false, disablePadding: true, label: 'Credits' },
  { id: 'minutesUsed', numeric: false, disablePadding: true, label: 'Prepaid minutes' },
  { id: 'userId', numeric: false, disablePadding: true, label: 'Customer' },
  { id: 'createdAt', numeric: false, disablePadding: true, label: 'Created' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    fontSize: 16,
    marginRight: theme.spacing(1),
    backgroundColor: colors.blue[900]
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const { data:rows, rowsPerPage, order, orderBy, setOrder, setOrderBy, setRowsPerPage, page, setPage, onSort  } = props;

  function handleRequestSort(event, property, c) {
    // flip the order of the current value if the column is the same
    onSort({ order: (order === 'desc' ? 1 : -1), orderBy: property })
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='medium'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={index}
                    >
                      {/*
                      <TableCell>
                        <div className={classes.nameCell}>
                          <Avatar
                            className={classes.avatar}
                            src={row.name}
                          >
                            {row.firstname && row.firstname.charAt(0).toUpperCase()}
                            {row.lastname && row.lastname.charAt(0).toUpperCase()}
                          </Avatar>
                          <div>
                            <Link
                              color='inherit'
                              component={RouterLink}
                              to={`/admin/customers/${row._id}/summary`}
                              variant='h6'
                            >
                              {`${row.firstname} ${row.lastname}`}
                            </Link>
                            <div>{row.email}</div>
                          </div>
                        </div>
                      </TableCell>
                      */}
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        {row.serviceName === 'revSpeech' ? 'tsAI' : row.serviceName}
                      </TableCell>
                      <TableCell>${row.cost && row.cost.toFixed(2)}</TableCell>
                      <TableCell>${row.creditsUsed ? row.creditsUsed / 100 : 0}</TableCell>
                      <TableCell>{row.minutesUsed ? row.minutesUsed : 0} min</TableCell>
                      <TableCell>
                        <div className={classes.nameCell}>
                          <Avatar
                            className={classes.avatar}
                          >
                            {row.user && row.user.firstname && row.user.firstname.charAt(0).toUpperCase()}
                            {row.user && row.user.lastname && row.user.lastname.charAt(0).toUpperCase()}
                          </Avatar>
                          <div>
                            <Link
                              color='inherit'
                              component={RouterLink}
                              to={`/admin/users/${row.user._id}`}
                              variant='h6'
                            >
                              {`${row.user && row.user.firstname} ${row.user && row.user.lastname}`}
                            </Link>
                            <div>{row.user&& row.user.email}</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{moment(row.createdAt).format('LLLL')}</TableCell>

                      <TableCell>
                        <Button
                          color='primary'
                          component={RouterLink}
                          size='small'
                          to={`/admin/jobs/${row._id}`}
                          variant='contained'
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/*emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )*/}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}
