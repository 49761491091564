import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  colors
} from '@material-ui/core';
import { useDataApi }  from 'custom-hooks';
import { EnhancedTable } from './components';
import axios from 'utils/axios';
import { Label } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    // width: 64
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Jobs = props => {
  const { className, email, user, ...rest } = props;
  const classes = useStyles();

  
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);

  const [order, setOrder] = useState(-1);
  const [orderBy, setOrderBy] = useState('createdAt');


  // const [searchQueryValue, onSetValue] = useQueryString("search");
  // const [search, setSearch] = useState(searchQueryValue ? decodeURIComponent(email) :'');

  const initialUrl = `/v1/user/${user._id}/jobs` +
    // `&search=${encodeURIComponent(email)}`+
    `?page=${page}`+
    `&limit=${rows}`+
    `&skip=${rows * page}`+
    `&orderDirection=${order}`+
    `&orderBy=${orderBy}`;

  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    initialUrl,
    {
      data: [],
      total: 0
    });

  const handleSort = (opts) => {
    setOrder(opts.order);
    setOrderBy(opts.orderBy);

    const url =  `/v1/user/${user._id}/jobs`+
      // `&search=${encodeURIComponent(email)}`+
      `?page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${opts.order}`+
      `&orderBy=${opts.orderBy}`;

    doFetch(url);
  }

  const handleChangePage = (event, page) => {
    setPage(page);
    const url =  `/v1/user/${user._id}/jobs`+
      // `&search=${encodeURIComponent(email)}`+
      `?page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url);
  };

  const handleChangeRows = event => {
    const rows = event.target.value;
    setRows(rows);
    const url =  `/v1/user/${user._id}/jobs`+
      // `&search=${encodeURIComponent(email)}`+
      `?page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url)
  };


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title={`Jobs`} />
        <CardContent className={classes.content}>
          <PerfectScrollbar options={{ suppressScrollY: true }}>
           
            <EnhancedTable
              data={data.data}
              rowsPerPage={rows}
              onSort={handleSort}
              order={order === -1 ? 'desc' : 'asc'}
              orderBy={orderBy}
              page={page}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setPage={setPage}
              />
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component='div'
            count={data.total}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRows}
            page={page}
            rowsPerPage={rows}
            rowsPerPageOptions={[5, 10, 25]}
            />
        </CardActions>
      </Card>
    </div>
  );
};

Jobs.propTypes = {
  className: PropTypes.string
};

export default Jobs;
