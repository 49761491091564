import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
    CircularProgress,
  Button,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  colors
} from '@material-ui/core';
import axios from 'utils/axios';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';

import { Label } from 'components';
import { CustomerEdit } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  action: {
    marginRight: 0,
    marginTop: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  }
}));

const CustomerInfo = props => {
  const {
    customer,
    className, setUser, ...rest } = props;
  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);


  const [showLoading, setShowLoading] = useState(false);
	const apiUrl = '/v1/user/activate/';

  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState('bottom');
  const [horizontal, setHorizontal] = useState('center');
  const [snackMessage, setSnackMessage] = useState('');

  const activate = () => {
		setShowLoading(true);
    console.log(customer._id )
    
		axios.post(apiUrl, { id: customer._id })
		 .then((result) => {
			 const { user } = result.data;
			 // setCards([...cards, card]);
       
       setUser(user)

       setShowLoading(false);
       setSnackMessage('User successfully activated!')
       setOpen(true);
		 })
     .catch((error) => setShowLoading(false));
	}


  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClose = () => { setOpen(false) }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='User info' />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>{customer.firstname}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Last Name</TableCell>
              <TableCell>{customer.lastname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>{moment(customer.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                {customer.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stripe</TableCell>
              <TableCell>
                {customer.stripeId}
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>

      {showLoading ?
        <div className={classes.progressWrapper}>
          <CircularProgress className={classes.progress} />
        </div>
        :
        <Button
          variant='contained'
          color='primary'
          disabled={customer.activated}
          onClick={activate}>
          {customer.activated ? 'Activated' : 'Activate'}
        </Button>
      }


        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
        <Button>
          <LockOpenIcon className={classes.buttonIcon} />
          Reset &amp; Send Password
        </Button>
        <Button>
          <PersonIcon className={classes.buttonIcon} />
          Login as Customer
        </Button>
      </CardActions>
      <CustomerEdit
        customer={customer}
        onClose={handleEditClose}
        open={openEdit}
        setUser={setUser}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={2000}
        transitionDuration={500}
        message={<span id='message-id'>{snackMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  // customer: PropTypes.object.isRequired
};

export default CustomerInfo;
