import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import axios from 'utils/axios';
import { Page } from 'components';
import { Header, JobInfo } from './components';

import DashboardLayout from 'layouts/Dashboard'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const JobDetails = props => {
  const { match, history } = props;
  const { id } = match.params;

  const classes = useStyles();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchOrder = (id) => {
      axios.get(`/v1/job/${id}`).then(response => {
        const { data } = response;

        if (mounted) {
          setOrder(data)
        }
      });
    };

    fetchOrder(id);

    return () => {
      mounted = false;
    };
  }, []);

  if (!order) {
    return null;
  }

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='Job Details'
      >
        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xl={3}
            xs={12}
          >
            <JobInfo order={order} />
          </Grid>
        </Grid>
      </Page>
    </DashboardLayout>
  );
};

export default JobDetails;
