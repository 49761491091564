/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useContext, lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import Dashboard from './views/Dashboard';
import OverviewView from './views/Overview';
import Login from 'views/Login';

import Users from './views/Users';
import UserDetails from './views/UserDetails';
import Projects from './views/Projects';
import ProjectDetails from './views/ProjectDetails';
import Invoices from './views/Invoices';
import InvoiceDetails from './views/InvoiceDetails';
import Jobs from './views/Jobs';
import JobDetails from './views/JobDetails';
import Settings from './views/Settings';


const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/admin/login' />
  },
  {
    path: '/admin',
    exact: true,
    component: () => <Redirect to='/admin/login' />
  },
  {
    path: '/admin/login',
    exact: true,
    component: Login
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to='/errors/error-404' />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/admin/dashboard',
        exact: true,
        component: Dashboard
      },
      {
        path: '/admin/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/admin/invoices',
        exact: true,
        component: Invoices
      },
      {
        path: '/admin/invoices/:id',
        exact: true,
        component:InvoiceDetails
      },
      {
        path: '/admin/customers',
        exact: true,
        component: Users
      },
      {
        path: '/admin/customers/:id',
        exact: true,
        component: UserDetails
      },
      {
        path: '/admin/customers/:id/:tab',
        exact: true,
        component: UserDetails
      },
      {
        path: '/admin/jobs',
        exact: true,
        component: Jobs
      },
      {
        path: '/admin/jobs/:id',
        exact: true,
        component: JobDetails
      },
      {
        path: '/admin/projects',
        exact: true,
        component: Projects
      },
      {
        path: '/admin/projects/:id',
        exact: true,
        component: ProjectDetails
      },
      {
        path: '/admin/projects/:id/:tab',
        exact: true,
        component: ProjectDetails
      },
      {
        path: '/admin/settings',
        exact: true,
        component: Settings
      },
      {
        path: '/admin/settings/:tab',
        exact: true,
        component: Settings
      },
      {
        component: () => <Redirect to='/errors/error-404' />
      }
    ]
  }
];

export default routes;
