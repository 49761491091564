/*eslint-disable eqeqeq*/
export const isWeb = process.env.APP_WEB == 'true';
export const isTest = process.env.NODE_ENV == 'test';
export const isDev = process.env.DEV == 'true' || process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV == 'production';
export const VERSION_STR = process.env.VERSION;
export const getVersionNum = () => parseFloat(VERSION_STR.substring(0, 3));

export const env = process.env.NODE_ENV;
export const web = process.env.APP_WEB == 'true';
export const port = process.env.PORT;

export const host = process.env.API_HOST || '';
export const userPath = process.env.API_USER_PATH;
export const authPath = process.env.API_AUTH_PATH;
export const projectPath = process.env.API_PROJECT_PATH;
export const transcribePath = process.env.API_TRANSCRIBE_PATH;
// export const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY;

export const stripeLivePublishableKey = process.env.STRIPE_LIVE_PUBLISHABLE_KEY;
export const stripeTestPublishableKey = process.env.STRIPE_TEST_PUBLISHABLE_KEY;

export const apiConfig = {
  host,
  authPath,
  userPath,
  projectPath,
  transcribePath,
  // stripePublishableKey
  stripeLivePublishableKey,
  stripeTestPublishableKey
};
export const appConfig = {
  web: process.env.APP_WEB == 'true',
};
export const socketConfig = {
  socketHost: process.env.SOCKET_HOST,
};

export const isVersionLessThan = v => v < getVersionNum();
export const isVersionGreaterEqThan = v => getVersionNum() >= v;
