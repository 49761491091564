import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const customer = {
    name: `${rest.user.firstname} ${rest.user.lastname}`
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component='h2'
        gutterBottom
        variant='overline'
      >
        Users
      </Typography>
      <Typography
        component='h1'
        variant='h3'
      >
        {customer.name}
      </Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
