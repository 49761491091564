import React, { useState, useEffect, Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CardTable from './tables/CardTable'
import axios from 'utils/axios';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

import AuthContext from 'context/auth/authContext';

const Subscription = props => {

  const authContext = useContext(AuthContext);
  const { loadUser } = authContext;

  const { className, user, ...rest } = props;
  const classes = useStyles();

	const [ prices, setPrices] = useState([])
	const [showLoading, setShowLoading] = useState(true);
	const apiUrl = '/v1/price';

  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState('bottom');
  const [horizontal, setHorizontal] = useState('center');
  const [snackMessage, setSnackMessage] = useState('');

	useEffect(() => {
    let mounted = true;
    const fetchPrices = () => {
      axios.get(apiUrl).then(response => {
        if (mounted) {
          setPrices(response.data);
          setShowLoading(false)
        }
      });
    };
    fetchPrices();
    return () => {
      mounted = false;
    };
  }, []);

  // if (!cards.length > 0) {
  //   return null;
  // }

	const addCard = data => {
		setShowLoading(true);
		axios.post(apiUrl, data)
		 .then((result) => {
			 const { card } = result.data;
			 setCards([...cards, card]);
       setShowLoading(false);
       setSnackMessage('Card successfully added!')
       setOpen(true);
		 })
     .catch((error) => setShowLoading(false));
	}

	const deleteCard = data => {
    setShowLoading(true);

		axios.delete(apiUrl, { params: data })
		 .then(result => {
			 const { cardId, default: defaultId } = result.data;

       // filter out the old card
       let newCards = cards.filter(card => card._id !== cardId);

       // assign the default
       newCards = newCards.map(item => {
         if(item._id === defaultId){
           return { ...item, default: true };
         } else {
           return item;
         }
       });

			 setCards(newCards);
       setShowLoading(false);
       setOpen(true);
		 })
     .catch((error) => setShowLoading(false));
	}

	const defaultCard = data => {
    setShowLoading(true);
		axios.post(`${apiUrl}/default`, data)
      .then(result => {
         const { cards } = result.data;
         setCards(cards);
         setShowLoading(false);
         setOpen(true);
      })
      .catch((error) => setShowLoading(false));
	}


  const changePlan = data => {
		setShowLoading(true);
		axios.post('/v1/subscription/changePlan', data)
		 .then((result) => {
       loadUser()
       const { user } = result.data;
       setShowLoading(false);
       setSnackMessage('Plan changed successfully!')
       setOpen(true);
		 })
     .catch((err) => {
       const { error } = err.response.data
       setShowLoading(false)
       setSnackMessage(error)
       setOpen(true);
     });
	}

  const handleClose = () => { setOpen(false) }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        classes={{ action: classes.action }}
        title='Plan and Pricing'
      />
      <Divider />
      <CardContent>
        <Card>
          {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            <CardContent className={classes.details}>
              <CardTable user={user} data={prices} changePlan={changePlan} />
            </CardContent>
          }
        </Card>
      </CardContent>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={2000}
        transitionDuration={500}
        message={<span id='message-id'>{snackMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;
