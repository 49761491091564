import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'utils/axios';

import { useDataApi }  from 'custom-hooks';
import { Page } from 'components';
import { Header, EnhancedTable, SearchBar } from './components';

import DashboardLayout from 'layouts/Dashboard';


import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Button,
  Table,
  TablePagination,
  Typography,
  colors
} from '@material-ui/core';

import { GenericMoreButton } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(3)}px`
  },
  results: {
    marginTop: theme.spacing(3)
  },
  tableRoot: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    fontSize: 16,
    marginRight: theme.spacing(1),
    backgroundColor: colors.blue[900]
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
}));

const User = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState(-1);
  const [orderBy, setOrderBy] = useState('createdAt');

  const initialUrl = `/v1/user?role=user`+
    `&search=`+
    `&page=${page}`+
    `&limit=${rows}`+
    `&skip=${rows * page}`+
    `&orderDirection=${order}`+
    `&orderBy=${orderBy}`;

  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    initialUrl,
    {
      data: [],
      total: 0
    });

  const handleSort = (opts) => {
    setOrder(opts.order);
    setOrderBy(opts.orderBy);

    const url = `/v1/user?role=user`+
      `&search=`+
      `&page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${opts.order}`+
      `&orderBy=${opts.orderBy}`;

    doFetch(url);
  }

  const handleChangePage = (event, page) => {
    setPage(page);
    const url = `/v1/user?role=user`+
      `&search=`+
      `&page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url);
  };

  const handleChangeRows = event => {
    const rows = event.target.value;
    setRows(rows);
    const url = `/v1/user?role=user`+
      `&search=`+
      `&page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url)
  };

  const handleClear = (event) => {
    setSearch('')
    const url = `/v1/user?role=user`+
      `&search=`+
      `&page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url)
  };

  const handleSearch = () => {
    const url = `/v1/user?role=user`+
      `&search=${search}`+
      `&page=${page}`+
      `&limit=${rows}`+
      `&skip=${rows * page}`+
      `&orderDirection=${order}`+
      `&orderBy=${orderBy}`;
    doFetch(url)
  };

  const handleNonPlan = () => {
    const url = `/v1/subscription/nonplan`;
    doFetch(url)
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='User '
      >
        <Header />
        <SearchBar
          onSearch={handleSearch}
          onClear={handleClear}
          onChange={handleChangeSearch}
          value={search}
        />
        {/* 
        <button onClick={handleNonPlan}>Convert non plan to Free</button>
      */}
        <div
          {...rest}
          className={clsx(classes.results, className)}
        >
          <Typography
            color='textSecondary'
            gutterBottom
            variant='body2'
          >
            {data.total} Records found. Page {page + 1} of{' '}
            {Math.ceil(data.total / rows)}
          </Typography>
          <Card>
            <CardHeader
              action={<GenericMoreButton />}
              title='All Users'
            />
            <Divider />
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (
              <div className={classes.progressWrapper}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <>
                <CardContent className={classes.content}>
                  <PerfectScrollbar>
                    <div className={classes.inner}>
                      <EnhancedTable
                        data={data.data}
                        rowsPerPage={rows}
                        onSort={handleSort}
                        order={order === -1 ? 'desc' : 'asc'}
                        orderBy={orderBy}
                        page={page}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setPage={setPage}
                      />
                    </div>
                  </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component='div'
                    count={data.total}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRows}
                    page={page}
                    rowsPerPage={rows}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </CardActions>
              </>
            )
          }
          </Card>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default User;
