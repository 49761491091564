import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import BarChartIcon from '@material-ui/icons/BarChart';
import moment from 'moment';


// import  AuthContext  from 'context/AuthContext';

import logo from '../../../../../assets/images/undraw_growth_analytics_8btt.svg'

const useStyles = makeStyles(theme => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 300
  }
}));

import AuthContext from 'context/auth/authContext';

const Header = props => {

    const authContext = useContext(AuthContext);
    const { login, clearErrors, isAuthenticated, loadUser } = authContext;
    const { user } = authContext.user || {}

  const { className, billCodes, balanceTransactions, ...rest } = props;

  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getGreetingTime = (m) => {
  	var g = null; //return g

  	if(!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

  	var split_afternoon = 12 //24hr time to split the afternoon
  	var split_evening = 17 //24hr time to split the evening
  	var currentHour = parseFloat(m.format("HH"));

  	if(currentHour >= split_afternoon && currentHour <= split_evening) {
  		g = "Afternoon";
  	} else if(currentHour >= split_evening) {
  		g = "Evening";
  	} else {
  		g = "Morning";
  	}

  	return g;
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        container
        justify='space-between'
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            component='h2'
            gutterBottom
            variant='overline'
          >
            Home
          </Typography>
          <Typography
            component='h1'
            gutterBottom
            variant='h3'
          >
            Good {getGreetingTime(moment())}, {user && user.firstname}
          </Typography>
          <Typography
            gutterBottom
            variant='subtitle1'
          >
            Here’s what’s happening with your web items today
          </Typography>
          
          {/* <Typography
            gutterBottom
            variant='subtitle1'
          >
            Balance: <b>${user && user.balance ? (Math.abs(user.balance)/100).toFixed(2) : '0.00'}</b>
          </Typography>
          <Button
            className={classes.summaryButton}
            edge='start'
            variant='contained'
            onClick={handleClickOpen}
          >
            <BarChartIcon className={classes.barChartIcon} />
            View history
          </Button> */}

        </Grid>
      </Grid>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {"Balance Transactions"}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Ending Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                {balanceTransactions && balanceTransactions.map((transaction,i) => (
                  <TableRow key={i}>
                    {/*<TableCell component='th' scope='row'>{transaction._id}</TableCell>*/}
                    <TableCell>{moment(transaction.createdAt).format('LLL')}</TableCell>
                    <TableCell><b>${(transaction.amount/100).toFixed(2).replace('-','')}</b></TableCell>
                    <TableCell><b>${(transaction.endingBalance/100).toFixed(2).replace('-','')}</b></TableCell>

                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
