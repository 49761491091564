import React, { useState, useContext } from 'react';
import axios from 'utils/axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Switch,
  TextField,
  Typography,
  colors,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AuthContext from 'context/auth/authContext';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const GeneralSettings = props => {
  const { profile, className, ...rest } = props;
  const authContext = useContext(AuthContext);
  const { login, clearErrors, isAuthenticated } = authContext;
  const { user } = authContext || {}
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(null);
  const [values, setValues] = useState({
    firstname: user ? user.firstname : '',
    lastname: user ? user.lastname : '',
    email: user ? user.email : '',
    username: user ? user.username : '',
    id: user ? user.id : '',
  });

  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };
  const close = () => {
    setStatus(null);
    setError(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    axios.put(`/v1/user/edit/${user.id}`, values).then(response => {
      if (response.data.error === true) {
        setStatus(response.data.detail);
        setError(true);
      } else {
        setStatus('Update successful');
      }
    }).catch(error => {
      console.log(error);
      setStatus(error.message);
      setError(true);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const severity = (error ? 'error' : 'success');
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {status && <Alert onClose={close} severity={severity}>{status}</Alert>}
      <form onSubmit={handleSubmit}>
        <CardHeader title='Profile' />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText='Please specify the first name'
                label='First name'
                name='firstname'
                onChange={handleChange}
                required
                value={values.firstname}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label='Last name'
                name='lastname'
                onChange={handleChange}
                required
                value={values.lastname}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label='Email Address'
                name='email'
                onChange={handleChange}
                required
                value={values.email}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type='submit'
            variant='contained'
          >
            Save Changes
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default GeneralSettings;
