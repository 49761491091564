import React , { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash'

import { Page } from 'components';
import {
  Header,
  Statistics,
  Notifications,
  Projects,
  UpcomingInvoice
} from './components';
import { Link as RouterLink } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Button } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import { Label, GenericMoreButton } from 'components';
import DashboardLayout  from 'layouts/Dashboard'



const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  notifications: {
    marginTop: theme.spacing(6)
  },
  projects: {
    marginTop: theme.spacing(6)
  },
  todos: {
    marginTop: theme.spacing(6)
  },
  upcomingInvoice: {
    marginTop: theme.spacing(3)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main
    }
  },
  arrowIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const Overview = props => {
  const query = new URLSearchParams(props.location.search);
  const redirectMinutes = Boolean(query.get('redirectMinutes'));
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [activeProjects, setActiveProjects] = useState(null);
  const [billCodes, setBillCodes] = useState([]);
  const [balanceTransactions, setBalanceTransactions] = useState([]);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchAnalytics = () => {
      axios
        .get('/v1/analytics?type=100')
        .then(response => {
          setData(response.data)
          const { 
            // discount, 
            // originalCost, 
            // totalAudioLength, 
            // totalCreditsUsed,
            // activeProjects, 
            upcomingInvoice, 
            invoiceItems, 
            // creditJobs, 
            user, 
            // billCodes,
          } = response.data
          // const invoice = { ...upcomingInvoice, userId: user,  invoiceItems, creditJobs, discount, originalCost, totalAudioLength, totalCreditsUsed, }
          const invoice = { 
            ...upcomingInvoice, 
            userId: user,  
            invoiceItems, 
            // creditJobs, 
            // originalCost, 
            // totalAudioLength, 
            // totalCreditsUsed, 
          }
          // setBillCodes(billCodes)
          setInvoice(invoice)
          // setActiveProjects(activeProjects)
        });
    };

    const fetchBalanceTransactions = () => {
      axios
        .get('/v1/balanceTransactions')
        .then(response => {
          // setData(response.data)
          const { balanceTransactions } = response.data
          // const invoice = { ...upcomingInvoice, userId: user,  invoiceItems, creditJobs }
          // setBillCodes(billCodes)
          // setInvoice(invoice)
          // setActiveProjects(activeProjects)
          setBalanceTransactions(balanceTransactions)
        });
    };

    fetchAnalytics();
    // fetchBalanceTransactions();

    return () => {
      mounted = false;
    };
  }, []);


  return (
    <DashboardLayout
      redirectMinutes={redirectMinutes||false}
    >
      <Page
        className={classes.root}
        title='Overview'
      >
        <Header billCodes={billCodes} balanceTransactions={balanceTransactions} />

        {!_.isNil(invoice) && !_.isNil(data) ? 
          <>
            <Statistics data={data} invoice={invoice} className={classes.statistics} />
            <div className={classes.header}>
              <Typography
                className={classes.title}
                variant='h5'
              >
                Upcoming Monthly Invoice
              </Typography>              
            </div>
            <UpcomingInvoice
              className={classes.upcomingInvoice}
              invoice={invoice}
            />
          </>
          :
          <div className={classes.progressWrapper}>
            <CircularProgress className={classes.progress} />
          </div>
        }
      </Page>
    </DashboardLayout>
  );
};

export default Overview;
