import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Filter = props => {
  const { open, onClose, onFilter, className, ...rest } = props;

  const classes = useStyles();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',

    plan: '',
    amount: [1, 7],

    size: [1,7],
    duration: ['00:00:00','03:00:00'],
    billingCode: ''

  };

  const [expandProject, setExpandProject] = useState(true);
  const [expandCustomer, setExpandCustomer] = useState(false);
  const [values, setValues] = useState({ ...initialValues });

  const handleClear = () => {
    setValues({ ...initialValues });
  };

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleToggleProject = () => {
    setExpandProject(expandProject => !expandProject);
  };

  const handleToggleCustomer = () => {
    setExpandCustomer(expandCustomer => !expandCustomer);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter(values);
  };

  return (
    <Drawer
      anchor='right'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant='temporary'
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size='small'
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.contentSectionHeader} onClick={handleToggleProject}>
              <Typography variant='h5'>Project</Typography>
              {expandProject ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandProject}>
              <div className={classes.contentSectionContent}>
                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label='Billing Code'
                    margin='dense'
                    name='billingCode'
                    onChange={event =>
                      handleFieldChange(
                        event,
                        'billingCode',
                        event.target.value
                      )
                    }
                    value={values.billingCode}
                    variant='outlined'
                  />
                </div>
                <div className={classes.formGroup}>
                  <Typography
                    component='p'
                    gutterBottom
                    variant='overline'
                  >
                    Money spent
                  </Typography>
                  <div className={classes.fieldGroup}>
                    <Typography
                      className={classes.minAmount}
                      variant='body1'
                    >
                      ${values.amount[0]}K
                    </Typography>
                    <Slider
                      className={classes.flexGrow}
                      max={20}
                      min={1}
                      onChange={(event, value) =>
                        handleFieldChange(event, 'amount', value)
                      }
                      value={values.amount}
                      valueLabelDisplay='auto'
                    />
                    <Typography
                      className={classes.maxAmount}
                      variant='body1'
                    >
                      ${values.amount[1]}K
                    </Typography>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <Typography
                    component='p'
                    gutterBottom
                    variant='overline'
                  >
                    File Size
                  </Typography>
                  <div className={classes.fieldGroup}>
                    <Typography
                      className={classes.minAmount}
                      variant='body1'
                    >
                      {values.size[0]}Mb
                    </Typography>
                    <Slider
                      className={classes.flexGrow}
                      max={20}
                      min={1}
                      onChange={(event, value) =>
                        handleFieldChange(event, 'size', value)
                      }
                      value={values.size}
                      valueLabelDisplay='auto'
                    />
                    <Typography
                      className={classes.maxAmount}
                      variant='body1'
                    >
                      {values.size[1]}Mb
                    </Typography>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <Typography
                    component='p'
                    gutterBottom
                    variant='overline'
                  >
                    Video Length
                  </Typography>
                  <div className={classes.fieldGroup}>
                    <Typography
                      className={classes.minAmount}
                      variant='body1'
                    >
                      {values.duration[0]}
                    </Typography>
                    <Slider
                      className={classes.flexGrow}
                      max={20}
                      min={1}
                      onChange={(event, value) =>
                        handleFieldChange(event, 'duration', value)
                      }
                      value={values.duration}
                      valueLabelDisplay='auto'
                    />
                    <Typography
                      className={classes.maxAmount}
                      variant='body1'
                    >
                      {values.duration[1]}
                    </Typography>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <div className={classes.contentSection}>
            <div
              className={classes.contentSectionHeader}
              onClick={handleToggleCustomer}
            >
              <Typography variant='h5'>Customer</Typography>
              {expandCustomer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandCustomer}>
              <div className={classes.contentSectionContent}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label='First Name'
                      margin='dense'
                      name='firstName'
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'firstName',
                          event.target.value
                        )
                      }
                      value={values.firstName}
                      variant='outlined'
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label='Last Name'
                      margin='dense'
                      name='lastName'
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'lastName',
                          event.target.value
                        )
                      }
                      value={values.lastName}
                      variant='outlined'
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label='Email address'
                      margin='dense'
                      name='email'
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'email',
                          event.target.value
                        )
                      }
                      value={values.email}
                      variant='outlined'
                    />
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={handleClear}
            variant='contained'
          >
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color='primary'
            fullWidth
            type='submit'
            variant='contained'
          >
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
