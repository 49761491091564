import React, {
  useRef,
  useState,
  useContext,
  useEffect
} from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  colors,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AuthContext from 'context/auth/authContext';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  trialButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
}));

function Account({
  handlePricingOpen,
  ...rest
}) {

  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, loadUser, user } = authContext;
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      localStorage.clear();
      history.push('/admin/login');
    } catch (error) {
      console.log(error)
    }
  };

  
  if (!user) {
    return null
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Hidden smDown>
          <Button
            className={classes.trialButton}
            onClick={handlePricingOpen}
            variant='contained'
            size='medium'
            >
            <FlashOnIcon className={classes.trialIcon} />
              Buy Minutes
          </Button>
        </Hidden>

        <Hidden mdUp>
          <Button
            className={classes.trialButton}
            onClick={handlePricingOpen}
            variant='contained'
            size='small'
            >
            <FlashOnIcon className={classes.trialIcon} />
              Buy Minutes
          </Button>
        </Hidden>

        <Avatar
          alt='User'
          className={classes.avatar}
          // src={account.user.avatar}
        />
        <Hidden smDown>
          <Typography
            variant='h6'
            color='inherit'
          >
            {`${user.firstname} ${user.lastname}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
