import React from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import Confirm from "../forms/Confirm"
import moment from 'moment';

const CardTable = props => {
  const cards = props.cards;
  const handleDelete = data => event => {
    const { cardId } = data;
    props.deleteCard({cardId})
  }

  const handleDefault = data => event => {
    const { cardId } = data;
    props.defaultCard({cardId})
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Brand</TableCell>
          <TableCell>Last 4</TableCell>
          <TableCell>Expire</TableCell>
          <TableCell>Added</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(cards.length > 0 && cards[0])
          ?
            cards.map(card => (
              <TableRow hover key={card.id}>
                <TableCell>{card.brand}</TableCell>
                <TableCell>{card.last4}</TableCell>
                <TableCell>{card.expMonth}/{card.expYear}</TableCell>
                <TableCell>{moment(card.createdAt).format('LLL')}</TableCell>
                <TableCell>
                  <Confirm title='Confirm' description='Are you sure?'>
                    {confirm => (
                      <>
                        <Button
                          color='primary'
                          disabled={card.default}
                          size='small'
                          variant='contained'
                          style={{marginRight: '1em'}}
                          onClick={confirm(handleDefault({ cardId: card._id }))}
                        >
                          {!card.default && 'Set'} default
                        </Button>
                        
                        <Button
                          size='small'
                          variant='contained'
                          onClick={confirm(handleDelete({cardId: card._id}))}
                          disabled={cards.length === 1}
                        >
                          Delete
                        </Button>
                       
                      </>
                    )}
                  </Confirm>
                </TableCell>
              </TableRow>
            ))
          :
            <TableRow><TableCell>No cards</TableCell></TableRow>
        }
      </TableBody>
    </Table>
  )
}

export default CardTable;
