import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import RefundFormContainer from "./RefundFormContainer";
import { useModal } from "react-modal-hook";
import AuthContext from 'context/auth/authContext';

const useStyles = makeStyles(theme => ({
  root: {},
  getAppIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Header = props => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { charge, className, setTransactions, addTransaction, transactions, ...rest } = props;
  const [_charge, setCharge] = useState(charge);
  const classes = useStyles();
  const [showEditModal, hideEditModal] = useModal(({ in: open, onExited }) => (
    <RefundFormContainer
      setCharge={setCharge}
      setTransactions={setTransactions}
      addTransaction={addTransaction}
      transactions={transactions}
      charge={_charge}
      open={open}
      onExited={onExited}
      onClose={hideEditModal}
    />
  ));


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems='flex-end'
        container
        justify='space-between'
        spacing={3}
      >
        <Grid item>
          <Typography
            component='h2'
            gutterBottom
            variant='overline'
          >
            Back
          </Typography>
          <Typography
            component='h1'
            variant='h3'
          >
            Charge #{charge._id}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={() => window.open(charge.url, '_blank')}
          >
            <GetAppIcon className={classes.getAppIcon} />
            Download PDF
          </Button>
          {user && user.role === 'admin' &&
            <Button
              color='default'
              variant='contained'
              className={classes.button}
              onClick={showEditModal}
            >
              <AttachMoneyIcon className={classes.getAppIcon} />
              Refund
            </Button>
          }
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Header;
