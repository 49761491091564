import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(
      config => {
        // Do something before request is sent
        const token = localStorage.getItem('token')
        config.headers['Authorization'] = `Bearer ${token ? token : ''}`
        return config
      },
     error => {

       console.log(error)
        Promise.reject(error)
      }
    )


export default instance;
