import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import { Label, GenericMoreButton } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    // padding: theme.spacing(6)
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  }
}));

const secondsToISOString = (seconds) => {
  const date = new Date(seconds * 1000);
  return date.toISOString();
}

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay; 
}

let myPaddingStyle = {
  paddingTop: 5,
  paddingBottom: 5,
}

const UpcomingInvoice = props => {
  const { subscription, invoice, className, ...rest } = props;
  // const planItemAmount = invoice.invoiceItems.filter(i => i.description.indexOf("1 × Transcriptive") > -1)[0].amount
  const user = invoice.userId
  const userCreated = new Date(user.createdAt).getTime()
  const formatCents = value => (value/100).toFixed(2)
  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>

      
      

        <Grid
          className={clsx(classes.marginTop, classes.dates)}
          container
          justify='space-between'
        >
          
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Period Start
            </Typography>
            <Typography>
              {invoice.periodStart && moment(secondsToISOString(invoice.periodStart)).format('MMMM Do YYYY, h:mm a')}
            </Typography>
            
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Period End
            </Typography>
            <Typography>
              {invoice.periodEnd && moment(secondsToISOString(invoice.periodEnd)).format('MMMM Do YYYY, h:mm a')}
            </Typography>
          </Grid>
        </Grid>

        {invoice.trialDaysLeft && invoice.trialDaysLeft > 0 ? 
              


          <div className={classes.marginTop}>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              <Label
                color={colors.lightGreen[600]}
                variant='contained'
                style={{
                  fontSize: '12px'
                }}
              >
                Will be billed in {invoice.trialDaysLeft} days
              </Label>
            </Typography>
          </div>
              :''}

        <div className={classes.marginTop}>
          <Typography
            component='h4'
            gutterBottom
            variant='overline'
          >
            Billed to
          </Typography>
          <Typography>
            {invoice.userId && invoice.userId.firstname ? invoice.userId.firstname : ""} {invoice.userId && invoice.userId.lastname ? invoice.userId.lastname : ""}<br />
            {invoice.userId && invoice.userId.email ? invoice.userId.email : ""}
          </Typography>
        </div>
        <Table className={classes.marginTop}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align='center'>Media Duration</TableCell>
              <TableCell align='center'>Cost per minute</TableCell>
              <TableCell align='center'>Service</TableCell>
              <TableCell align='center'>Payment Method</TableCell>
              <TableCell align='center'>Credits</TableCell>
              <TableCell align='right'>Credit Card</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {invoice.invoiceItems.filter(item => item.name.indexOf('1 × Transcriptive') < 0 ).length > 0 ? invoice.invoiceItems.filter(item => item.name.indexOf('1 × Transcriptive') < 0 ).map((item, index) => (
            <TableRow key={item._id}>
              <TableCell size='small'>
                {index === 0 ?
                    <>
                    <br />
                    <Typography
                      component='h6'
                      variant='h6'
                      color='textSecondary'
                    >
                    TRANSCRIPTION CHARGES
                    </Typography><br />
                    </>
                  :''}
                <Typography
                  component='h6'
                  variant='h6'
                >
                  {item.createdAt}
                </Typography>
                {item.name }
              </TableCell>
              <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                <Typography gutterBottom > 
                  {item.audioLength ? Math.ceil(item.audioLength/60) + ' min' : ''}
                </Typography> 
              </TableCell>


              <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                <Typography gutterBottom > 
                  {item.costPerMinute ? `$${item.costPerMinute}` : ''}
                </Typography> 
              </TableCell>

              <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                <Typography gutterBottom > 
                  {item.serviceName}
                </Typography> 
              </TableCell>

              <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom', textTransform: 'capitalize'  }} > 
                <Typography gutterBottom > 
                  {item.method ? item.method : ''}
                </Typography> 
              </TableCell>
              <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                <Typography gutterBottom > 
                  {item.credits ? item.credits : 'None'}
                </Typography> 
              </TableCell>
              <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                <Typography gutterBottom> 
                  ${item.cost}
                </Typography> 
              </TableCell>

            </TableRow>
          )) : ''}

          {invoice.invoiceItems.filter(item => item.name.indexOf('1 × Transcriptive') > -1 ).map((item, index) => (
              <>
                <TableRow key={item._id}>
                  <TableCell size='small' style={{ verticalAlign: 'bottom'}}>
                      <br />
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                      >
                      SUBSCRIPTION CHARGES
                      </Typography><br />

                    <Typography
                      component='h6'
                      variant='h6'
                    >
                    {item.createdAt}
                    </Typography>
                    {item.name }
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                    {`$${(item.amount/100).toFixed(2)}`}
                    </Typography> 
                  </TableCell>
                  
                </TableRow>
              </>
            ))}           

          {invoice.subtotal && invoice.subtotal ? 
              <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'><b>Subtotal</b></TableCell>
              <TableCell style={myPaddingStyle} align='right'>
                <b>{`$${(invoice.subtotal/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
          :''}

          {invoice.discount && invoice.discount.coupon ? 
            <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'> {invoice.discount.coupon.name && invoice.discount.coupon.name.toLowerCase().indexOf('premiere') > -1 ? 'Premiere' : 'Invoice'} Discount ({`$${(invoice.discount.coupon.amount_off/100).toFixed(2)}`} off)</TableCell>
              <TableCell style={myPaddingStyle} align='right'>
              <b>{`-$${(invoice.discount.coupon.amount_off/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
          :''}

            <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'><b>Total</b></TableCell>
              <TableCell style={myPaddingStyle} align='right'>
                <b>${invoice.total ? invoice.total : '0.00'}</b>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>

      </CardContent>
    </Card>
  );
};

UpcomingInvoice.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default UpcomingInvoice;
