import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ItemsCarousel from 'react-items-carousel';
import range from 'lodash/range';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  Link,
  Avatar,
  AppBar, Toolbar
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import AuthContext from 'context/auth/authContext';
import { Page } from 'components';
import gradients from 'utils/gradients';
import { LoginForm } from './components';
import axios from 'axios'

import customer1 from '../../../assets/images/avatars/customer1.png'
import customer2 from '../../../assets/images/avatars/customer2.png'
import customer3 from '../../../assets/images/avatars/customer3.png'
import customer4 from '../../../assets/images/avatars/customer4.png'

import auth from '../../../assets/images/auth.png'
import logo from '../../../assets/images/avatars/avatar_2-1.png'

const useStyles = makeStyles(theme => ({
  name: {
    color: '#FFF'
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  mainContent: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  topBar: {
    backgroundColor: '#333',
    color: '#FFF',
    zIndex: 2,
    position: 'relative'
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Login = props => {

  const authContext = useContext(AuthContext);
  const apiError = authContext.error;
  const { login, clearErrors, isAuthenticated, userError, user, loadUser } = authContext;
  
  const query = new URLSearchParams(props.location.search);
  const classes = useStyles();
  const [msg, setMsg] = useState(null);
  const [children, setChildren] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const data = [
    {
      logo: customer1,
      name: 'David Kudell',
      title: 'Rimrock Creative Media, Producer/Director',
      text: 'Faced with the tedious task of typing out transcripts for an hour’s worth of videos for a Pharmaceutical client, I discovered Transcriptive. What a time saver! The accuracy is very impressive, and it easily saved me a day’s labor, paying for itself immediately. It’s easy to use, and the team at Digital Anarchy is very responsive to questions and feature suggestions.'
    },
    {
      logo: customer2,
      name: 'Ashby Wratchford',
      title: 'Paladin Media Group',
      text: 'Transcribing takes time and patience.Incorporating Transcriptive into our workflow has been incredibly important to speed up our video production tunraround time and lower costs.Before Transcriptive, the transcribing process was oftent skipped. Transcriptive allows our post-production crew to quickly get an idea of what was said and plan how each segment will be cut. We are happy with the changes and ready to try other tools now offered by Transcriptive Suite of products.'
    },
    {
      logo: customer3,
      name: 'Chris McElroy',
      title: 'Michingan Media',
      text: 'Transcriptive for Premiere enabled us to transcribe,find sound bites, and share content faster. The web app makes it easy to collaborate among a group who can then review the transcripts for content decision-making on Social Media. We in the video department at Univ.Michigan are very impressed and find Transcriptive very useful.'
    },
    {
      logo: customer4,
      name: 'Chris Neufeld',
      title: 'Willow Park Church, Online Video',
      text: 'One of the most useful and time saving features of Transcriptive is the fact that our lead pastor was born in the United Kingdom, but has done ministry in many parts of the world which has resulted in a very unique accent. While there are many speech-to-text engines available, none could understand his accent. Transcriptive works very well. This saves us a lot of time too, being that we used to spend many hours manually transcribing.'
    },
  ]
  const createChildren = n => setChildren(
    range(n).map(i => {

      return (
        <div key={i} >
          <Typography
            color='inherit'
            variant='subtitle1'
          >
            {data[i].text}
          </Typography>
          <div className={classes.person}>
            <Avatar
              alt='Person'
              className={classes.avatar}

            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  textAlign: 'center'
                }}
                src={data[i].logo}
              />
            </Avatar>
            <div>
              <Typography
                color='inherit'
                variant='body1'
              >
                {data[i].name}
              </Typography>
              <Typography
                color='inherit'
                variant='body2'
              >
                {data[i].title}
              </Typography>
            </div>
          </div>
        </div>)
      }
    )
  );
  const changeActiveItem = (activeItemIndex) => setActiveItemIndex(activeItemIndex);

      
  useEffect(() => {
    let mounted = true;
    loadUser();
    return () => {
      mounted = false;
    };
  }, []);


  useEffect(() => {
    let mounted = true;
    if (mounted) {
      createChildren(3);
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Fragment>
      <AppBar
        className={classes.topBar}
        color='primary'
        style={{ boxShadow: 'none' }}
      >
        <Toolbar>
        <RouterLink to='/'>
          <Typography
            variant='h4'
            className={classes.name}
          >
          Transcriptive
          </Typography>
        </RouterLink>
        </Toolbar >
      </AppBar>
      <main className={classes.mainContent}>
        <Page
          className={classes.root}
          title='Login'
        >
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <LockIcon className={classes.icon} />
              {/*userError ? 
                <>
                  {msg}
                  <AddCardForm addCard={addCard} />
                </> 
                : ''*/}
              <Typography
                gutterBottom
                variant='h3'
              >
                Sign in
              </Typography>
              <Typography variant='subtitle2'>
                Sign in on the internal platform
              </Typography>

              <LoginForm
                redirectMinutes={Boolean(query.get('redirectMinutes'))||false}
                className={classes.loginForm} />

              <Divider className={classes.divider} />
              <Typography variant='subtitle2'>
                Create a new Account at &nbsp;
                <Link
                  align='center'
                  color='secondary'
                  href='https://app.transcriptive.com/?action=create'
                  variant='subtitle2'
                >
                  Transcriptive.com
                </Link>
              </Typography>
            </CardContent>
            <CardMedia
              className={classes.media}
              image={auth}
              title='Cover'
            >

              <ItemsCarousel

                // Carousel configurations
                numberOfCards={1}
                gutter={12}
                showSlither={false}
                firstAndLastGutter={true}
                freeScrolling={false}

                // Active item configurations
                requestToChangeActive={changeActiveItem}
                activeItemIndex={activeItemIndex}
                activePosition={'center'}

                chevronWidth={24}
                rightChevron={'>'}
                leftChevron={'<'}
                outsideChevron={false}
              >
                {children}
              </ItemsCarousel>

            </CardMedia>
          </Card>
        </Page>
      </main>
    </Fragment>
  );
};

export default Login;
