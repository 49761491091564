import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Label from 'components/Label';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
}));

const StatsCard = ({ className, title, text, ...rest }) => {
  const classes = useStyles();
  return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
        >
          <Box flexGrow={1}>
            <Typography
              align='center'
              component='h3'
              gutterBottom
              variant='overline'
              color='textSecondary'
            >
              {title}
            </Typography>
            <Typography
              align='center'
              variant='h3'
              color='textPrimary'
            >
              {text}
            </Typography>
          </Box>
        </Card>
  );
};

StatsCard.propTypes = {
  className: PropTypes.string
};

export default StatsCard;
