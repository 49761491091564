import React, { useState, useEffect }  from 'react';
import axios from 'utils/axios';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';

import { Page } from 'components';
import { Header, Summary, Invoices, Jobs, Projects, Subscription } from './components';


import DashboardLayout from 'layouts/Dashboard';

import {
  CircularProgress
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const UserDetails = props => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;

  const [isLoading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [remote_invoices, setRemoteInvoices] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [user, setUser] = useState({});

  const _fetchUser = (id) => {
    axios.get(`/v1/user/get/${id}`).then(response => {
      let { payments, invoices, jobs, user, projects, remote_invoices, remote_subscription, remote_customer, subscription, payment_methods } = response.data;
      setPayments(payments);
      setInvoices(invoices);
      setRemoteInvoices(remote_invoices);
      setJobs(jobs);
      setProjects(projects);
      setPaymentMethods(payment_methods);
      setSubscription(subscription);

      user.subscription = subscription;
      user.remote_customer = remote_customer;
      user.remote_subscription = remote_subscription;
      setUser(user);
      setLoading(false);
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      _fetchUser(id);
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'summary', label: 'Summary' },
    { value: 'projects', label: 'Web Items' },
    { value: 'jobs', label: 'Jobs' },
    { value: 'invoices', label: 'Payments' },
    { value: 'subscription', label: 'Subscription' }
  ];

  if (!tab) {
    return <Redirect to={`/admin/users/${id}/summary`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to='/errors/error-404' />;
  }

  return (
    <DashboardLayout>
      <Page
        className={classes.root}
        title='User Details'
      >
      {isLoading ? (
        <div className={classes.progressWrapper}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : (
        <>
          <Header user={user} />
          <Tabs
            className={classes.tabs}
            onChange={handleTabsChange}
            scrollButtons='auto'
            value={tab}
            variant='scrollable'
          >
            {tabs.map(tab => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider className={classes.divider} />
          <div className={classes.content}>
            {tab === 'summary' && <Summary 
              setUser={setUser} 
              fetchUser={_fetchUser} 
              paymentMethods={paymentMethods}
              subscription={subscription} 
              user={user} 
              invoices={invoices} 
              
              />}
            {tab === 'projects' && <Projects projects={projects} email={user.email} />}
            {tab === 'jobs' && <Jobs jobs={jobs}  user={user} email={user.email} />}
            {tab === 'invoices' && <Invoices payments={payments} invoices={invoices} remote_invoices={remote_invoices} />}
            {tab === 'subscription' && <Subscription user={user} />}
          </div>
        </>
      )}

      </Page>
    </DashboardLayout>
  );
};

UserDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default UserDetails;
