import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(6)
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  }
}));

const Details = props => {
  const { invoice, transactions, className, ...rest } = props;
  const classes = useStyles();

  const secondsToISOString = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toISOString();
  }

  let myPaddingStyle = {
    paddingTop: 5,
    paddingBottom: 5,
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      <div>
          <Typography
            component='h1'
            variant='h4'
          >
            Digital Anarchy, Inc.
          </Typography>
          <Typography><br />
            226 Tulare St<br />
            Brisbane, CA 94005<br />
            United States<br />
            +1 415-287-6069
          </Typography>
        </div>
        <Grid
          className={clsx(classes.marginTop, classes.dates)}
          container
          justify='space-between'
        >
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Date of issue
            </Typography>
            <Typography>
            {invoice.date}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Status
            </Typography>
            <Typography>
              {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Billing Cycle
            </Typography>
            <Typography>
              {invoice.period}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Invoice Number
            </Typography>
            <Typography>
              {invoice.index}
            </Typography>
          </Grid>
        </Grid>
             
        <Grid
          className={clsx(classes.dates)}
          container
          justify='space-between'
        >
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Billed to
            </Typography>
            <Typography>
              {invoice.user && invoice.user.firstname ? invoice.user.firstname : ""} {invoice.user && invoice.user.lastname ? invoice.user.lastname : ""}<br />
              {invoice.user && invoice.user.email ? invoice.user.email : invoice.email}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Payment method
            </Typography>
            <Typography>
              {invoice.source ? 
                <>
                  {invoice.source.brand} ****{invoice.source.last4}<br />
                  {invoice.source.exp_month}/{invoice.source.exp_year}
                </>
              : ''}
            </Typography>
          </Grid>
         
        </Grid>
        
        
        <Table className={classes.marginTop}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align='center'>Media Duration</TableCell>
              <TableCell align='center'>Cost per minute</TableCell>
              <TableCell align='center'>Service</TableCell>
              <TableCell align='center'>Payment Method</TableCell>
              <TableCell align='center'>Credits</TableCell>
              <TableCell align='right'>Credit Card</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.items.filter(item => item.name.indexOf('1 × Transcriptive') < 0 ).length > 0 ? invoice.items.filter(item => item.name.indexOf('1 × Transcriptive') < 0 ).map((item, index) => (
              <>
                <TableRow key={item._id}>
                  <TableCell size='small' style={{ verticalAlign: 'bottom'}}>
                    {index === 0 ?
                      <>
                      <br />
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                      >
                      TRANSCRIPTION CHARGES
                      </Typography><br />
                      </>
                    :''}

                    <Typography
                      component='h6'
                      variant='h6'
                    >
                    {item.createdAt}
                    </Typography>
                    {item.name }
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.audioLength ? Math.ceil(item.audioLength/60) + ' min' : '0 min'}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                    {item.costPerMinute ? `$${item.costPerMinute}` : ''}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.serviceName}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom', textTransform: 'capitalize'  }} > 
                    <Typography gutterBottom > 
                      {item.method ? item.method : ''}
                    </Typography> 
                  </TableCell>
                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.credits ? item.credits : 'None'}
                    </Typography> 
                  </TableCell>
                  <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom> 
                      ${item.cost}
                    </Typography> 
                  </TableCell>
                  
                </TableRow>
              </>
            )) : ''}

            {invoice.items.filter(item => item.name.indexOf('1 × Transcriptive') > -1 ).map((item, index) => (
              <>
                <TableRow key={item._id}>
                  <TableCell size='small' style={{ verticalAlign: 'bottom'}}>
                      <br />
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                      >
                      SUBSCRIPTION CHARGES
                      </Typography><br />

                    <Typography
                      component='h6'
                      variant='h6'
                    >
                    {item.createdAt}
                    </Typography>
                    {item.name }
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                    {`$${(item.amount/100).toFixed(2)}`}
                    </Typography> 
                  </TableCell>
                  
                </TableRow>
              </>
            ))}
            {/* <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align='right'><b>Subtotal</b></TableCell>
              <TableCell align='right'>
                $
                {(invoice.subtotal/100).toFixed(2)}
              </TableCell>
            </TableRow> */}
            
            {invoice.subtotal ? 
              <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'><b>Subtotal</b></TableCell>
              <TableCell style={myPaddingStyle} align='right'>
                <b>{`$${(invoice.subtotal/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
            :''}

            {invoice.discount && invoice.discount.coupon ? 
              <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'>Discount ({`$${(invoice.discount.coupon.amount_off/100).toFixed(2)}`} off)</TableCell>
              <TableCell style={myPaddingStyle} align='right'>
              <b>{`-$${(invoice.discount.coupon.amount_off/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
            :''}
            <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'><b>Total</b></TableCell>
              <TableCell style={myPaddingStyle} align='right'>
                <b>${invoice.total}</b>
              </TableCell>
            </TableRow>
            {invoice.amount_paid ? 
              <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'>Amount paid</TableCell>
              <TableCell style={myPaddingStyle} align='right'>
              <b>{`-$${(invoice.amount_paid/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
            :''}

          {invoice.amount_remaining > -1 ? 
              <TableRow>
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} />
              <TableCell style={myPaddingStyle} align='right'><b>Amount Due</b></TableCell>
              <TableCell style={myPaddingStyle} align='right'>
              <b>{`$${(invoice.amount_remaining/100).toFixed(2)}`}</b>
              </TableCell>
            </TableRow>
            :''}
          </TableBody>
        </Table>


        {invoice.refunds.length ? 
          <>
            <Grid
              className={clsx(classes.dates)}
              container
              justify='flex-startq'
            >
              <Grid item>
                <Typography
                  component='h4'
                  gutterBottom
                  variant='overline'
                >
                  Refunds
                </Typography>
              </Grid>
            </Grid>
            
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={myPaddingStyle} >Amount</TableCell>
                  <TableCell style={myPaddingStyle} >Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.refunds && invoice.refunds.map(item => (
                  <TableRow key={item.id}>
                    <TableCell style={myPaddingStyle} >
                      ${(item.amount/100).toFixed(2)}
                    </TableCell>
                    <TableCell style={myPaddingStyle} >{moment.unix(item.created).format('MMM Do, YYYY, h:mm a')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        :''}
        
      </CardContent>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Details;
