import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
// import Logo from 'src/components/Logo';
import Account from './Account';

import { PrepaidModal } from 'components';


const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: '#333333'
  },
  logo : {
    color: '#ffffff'
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  redirectMinutes,
  ...rest
}) {
  const classes = useStyles();

  const [pricingModalOpen, setPricingModalOpen] = useState(redirectMinutes);

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color='inherit'
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to='/'>
            {/*<Logo />*/}
            <Typography 
              variant='h4' 
              className={classes.logo}
            >
              Transcriptive
            </Typography>
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Box ml={2}>
          <Account handlePricingOpen={handlePricingOpen} />
        </Box>
      </Toolbar>


      <PrepaidModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />

    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
