import React, { useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Input from "@material-ui/core/Input";

import axios from 'utils/axios';
import useForm from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));


const BalanceFormContainer = ({
  user,
  setUser,
  setSnackbarOpen,
  onClose,
  ...rest
}) => {

  const classes = useStyles();
  const apiUrl = '/v1/user';
  const [showLoading, setShowLoading] = useState(false);
  const [balance, setBalance] = useState(user ? user.balance : 0)

  const { register, handleSubmit, errors, getValues, setValue, formState, triggerValidation } = useForm({
    mode: 'onChange',
    defaultValues: {
      balance,
    }
  });

  const onSubmit = data => {
      data.id = user._id;
      setShowLoading(true);

      axios
        .post(`${apiUrl}/balance`, data)
        .then(result => {
          setBalance(parseInt(data.balance))
          setUser({...user, balance: data.balance})
          setValue('balance', parseInt(data.balance),  true)
          setShowLoading(false);
          onClose();
          setSnackbarOpen();
        })
        .catch((error) => {
          setShowLoading(false)
        });
  }

  const handleClose = () => { setOpen(false) }

  const handleChange = event => {
    event.preventDefault();
    setValue('balance', event.target.value,  true)
  }

  useEffect(() => {
    register(
      { name: 'balance' },
      {
        required: 'Required',
        pattern: {
          value: /\d*[0-9]\d*/,
          message: "Must be a number greater or equal to 0."
        }
      }
    )
  }, [register]);

  const values = getValues();

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      aria-labelledby='trust-dialog-slide-title'
    >
      <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id='trust-dialog-slide-title'>
          Edit Balance
        </DialogTitle>
        <DialogContent>
          {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            <TextField
              fullWidth
              className={classes.textField}
              name='balance'
              label='Balance'
              defaultValue={values.balance}
              error={!formState.isValid}
              helperText={errors.balance ? errors.balance.message : ''}
              margin='normal'
              variant='outlined'
              onChange={handleChange}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' disabled={!formState.isValid}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BalanceFormContainer;
