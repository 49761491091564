import React from 'react'
import { Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import Confirm from "../forms/Confirm"
import moment from 'moment';

const CardTable = props => {
  const handleDelete = data => event => {
    const { cardId } = data;
    props.deleteCard({cardId})
  }

  const handleDefault = data => event => {
    const { cardId } = data;
    props.defaultCard({cardId})
  }

  const handleChangePlan = data => event => {
    const { priceId } = data;
    const { _id } = props.user;
    props.changePlan({ id: _id, priceId})
  }

  const needCard = () => {
    return () => {
      props.history.push('/admin/settings/card');
    }
  }

  const renderPlanDescription = (nickname) => {
    switch(nickname) {
      case 'Free':
        return (
          <Typography variant='caption' display='block' gutterBottom>
            {`Limited access.  For users that only need to transcribe (Premiere Pro users) or review/approve (no editing) transcripts online. Captions exporting, transcript editing, and other tools will be disabled.`}
          </Typography>
        );
      case 'Producer (Monthly)':
        return (
          <Typography variant='caption' display='block' gutterBottom>
            {`Transcribe, edit, export captions or text files, add comments, strikethrough text, and share transcripts with editors using Transcriptive for Premiere Pro. If you are a Transcriptive for Premiere Pro user, `} <a target='_blank' href='https://digitalanarchy.com/blog/video-editing-plugins/how-to-get-transcriptive-com-for-8mo-or-96year/'>link your account and serial number</a> {' to pay $8/month.'}
          </Typography>
        );
      case 'Producer (Annual)':
        return (
          <Typography variant='caption' display='block' gutterBottom>
            {`Guarantee annual access to all Producer Monthly features. If you are a Transcriptive for Premiere Pro user, `} <a target='_blank' href='https://digitalanarchy.com/blog/video-editing-plugins/how-to-get-transcriptive-com-for-8mo-or-96year/'>link your account and serial number</a> {' to pay $96/year.'}
          </Typography>
        );
      default:
        return '';
    }
  }

  return (
    <Table>
      <TableBody>
        {props.data.length > 0 && props.user
          ?
            props.data.map(row => (
              <TableRow hover key={row.id}>
                <TableCell
                  width='50%'
                >
                  <strong>{row.nickname} {props.user.subscriptionId.priceId === row.id && props.user.remote_subscription.discount !== null && row.unit_amount > 0 ? 'with Discount' : ''}</strong>
                  <br />
                  {renderPlanDescription(row.nickname)}
                </TableCell>
                <TableCell>
                  {props.user.subscriptionId.priceId === row.id && props.user.remote_subscription.discount && props.user.remote_subscription.discount.coupon && row.unit_amount > 0
                    ?
                      <strong>${((row.unit_amount-props.user.remote_subscription.discount.coupon.amount_off)/100).toFixed(2)}</strong>
                    :
                      <strong>${(row.unit_amount/100).toFixed(2)}</strong>
                  }
                </TableCell>

                <TableCell>
                  <Confirm 
                    // title='Please Confirm' 
                    description={props.hasCard || row.unit_amount === 0 ? `Are you sure you want to change to ${row.nickname}?` : 'This plan requires a credit card. Click OK to add one'}
                    subtext={
                      props.hasCard && row.unit_amount > 0 ? (
                        <p>
                          Your first subscription payment will be charged in 30 days.
                          Click <a style={{ color: '#2196f3' }} href='/admin/overview' target='_blank'>Home</a> to see your upcoming charges and billing date.
                          Switching to the Free plan before that date will remove upcoming subscription charges.<br /><br />
                          Contact <a href='mailto:sales@digitalanarchy.com'>sales@digitalanarchy.com</a> with any questions.
                        </p>
                      ) : ''
                    }
                    >
                    {confirm => (
                      <Button
                        size='small'
                        variant='contained'
                        onClick={(row.unit_amount === 0 || props.hasCard ? confirm(handleChangePlan({priceId: row.id })) : confirm(needCard()))}
                        disabled={props.user && props.user.subscriptionId && props.user.subscriptionId.priceId === row.id}
                        color={props.user && props.user.subscriptionId && props.user.subscriptionId.priceId !== row.id ? 'primary' : ''}
                      >
                          {props.user && props.user.subscriptionId && props.user.subscriptionId.priceId === row.id ? 'Current' : 'Change Plan'}
                      </Button>
                    )}
                  </Confirm>
                </TableCell>
              </TableRow>
            ))
          :
            <TableRow><TableCell>No Data</TableCell></TableRow>
        }
      </TableBody>
    </Table>
  )
}

export default CardTable;
