import React, { useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Input from "@material-ui/core/Input";

import axios from 'utils/axios';
import useForm from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));


const RefundFormContainer = ({
  invoice,
  setInvoice,
  transactions,
  setTransactions,
  addTransaction,
  setSnackbarOpen,
  onClose,
  ...rest
}) => {

  const classes = useStyles();
  const apiUrl = '/v1/invoice';
  const [showLoading, setShowLoading] = useState(false);

  const { register, handleSubmit, errors, getValues, setValue, formState, triggerValidation } = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: 0
    }
  });

  const onSubmit = data => {
      data.invoiceId = invoice.id;
      setShowLoading(true);
      axios
        .post(`${apiUrl}/refund`, data)
        .then(result => {
          setShowLoading(false);
          onClose();
        })
        .catch((error) => {
          setShowLoading(false)
        });
  }

  const handleClose = () => { setOpen(false) }

  const handleChange = event => {
    event.preventDefault();
    setValue('amount', event.target.value,  true)
  }

  useEffect(() => {
    register(
      { name: 'amount' },
      {
        required: 'Required',
        pattern: {
          value: /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/,
          message: "Must be a valid dollar amount."
        }
      }
    )
  }, [register]);

  const values = getValues();

  console.log(errors)

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      aria-labelledby='refund-dialog-slide-title'
    >
      <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id='refund-dialog-slide-title'>
          Refund
        </DialogTitle>
        <DialogContent>
          {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            <TextField
              fullWidth
              className={classes.textField}
              name='amount'
              label='Amount'
              defaultValue={values.amount}
              error={!formState.isValid}
              helperText={errors.amount ? errors.amount.message : ''}
              margin='normal'
              variant='outlined'
              onChange={handleChange}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' disabled={!formState.isValid}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RefundFormContainer;
