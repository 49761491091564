import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import axios from 'utils/axios';
import { CustomerInfo, Invoices, SendEmails, OtherActions } from './components';
import SetTrust from './SetTrust';
import SetMinutes from './SetMinutes';
import SetBalance from './SetBalance';
import SetDiscount from './SetDiscount';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Summary = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [customer, setCustomer] = useState();

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <CustomerInfo
          customer={rest.user}
          user={rest.user}
          setUser={rest.setUser}
          subscription={rest.subscription}
          paymentMethods={rest.paymentMethods} />
      </Grid>

      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <Invoices
          paymentMethods={rest.paymentMethods}
          invoices={rest.invoices} />
      </Grid>

      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <SetTrust user={rest.user} setUser={rest.setUser} /><br />
        <SetMinutes user={rest.user} /><br />
        <SetBalance user={rest.user} /><br />
        <SetDiscount 
          user={rest.user} 
          fetchUser={rest.fetchUser} 
        />
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        {/*<OtherActions />*/}
      </Grid>

    </Grid>
  );
};

Summary.propTypes = {
  className: PropTypes.string
};

export default Summary;
