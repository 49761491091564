import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import "chartjs-plugin-lineheight-annotation";

import palette from 'theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));

const Chart = props => {

  const { data: dataProp, labels, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        label: 'Payments',
        backgroundColor: colors.green[500],
        borderColor: colors.green[500],
        data: dataProp.paymentsData,
        fill: false,
        pointRadius: 0
      },
      {
        label: 'Refunds',
        backgroundColor: colors.red[500],
        borderColor: colors.red[500],
        data: dataProp.refundsData,
        fill: false,
        pointRadius: 0
      },
      {
        label: 'Service Costs',
        backgroundColor: colors.blue[600],
        borderColor: colors.blue[600],
        data: dataProp.serviceCostsData,
        fill: false,
        pointRadius: 0
      }
    ],
    labels
  };

  const options = {
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      hover: {
       mode: 'index',
       intersect: false
      },
      lineHeightAnnotation: {
        always: false,
        hover: true,
        color: colors.grey[300],
        shadow: false,
        noDash: true,
      },
      maintainAspectRatio: false,
      legend: {
        display: true
      },
      scales: {
        xAxes: [
          {
            maxBarThickness: 20,
            barPercentage: 1,
            categoryPercentage: 0.25,
            ticks: {},
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback: function(label, index, labels) {
                return `$${label}`;
              },
              beginAtZero: true,
              min: 0,
            },
            display: true,
            gridLines: {
              color: palette.divider,
              drawBorder: false
            }
          }
        ]
      },
      responsiveAnimationDuration: 1000
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Bar
        data={data}
        options={options}
      />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired
};

export default Chart;
