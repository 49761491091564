import React from 'react'
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './StripeCardForm';

const AddCardForm = props => {
	return (
		<Elements>
      <InjectedCheckoutForm addCard={props.addCard} buttonText={props.buttonText} />
    </Elements>
	)
}

export default AddCardForm
