import axios from 'axios';

const setAuthToken = token => {
  delete axios.defaults.headers.common['Authorization'];
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

export default setAuthToken;
