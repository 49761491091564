import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(6)
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  }
}));

const Details = props => {
  const { charge, transactions, className, ...rest } = props;
  const classes = useStyles();

  const secondsToISOString = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toISOString();
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div>
          <Typography
            component='h1'
            variant='h4'
          >
            Digital Anarchy, Inc.
          </Typography>
          <Typography><br />
            226 Tulare St<br />
            Brisbane, CA 94005<br />
            United States<br />
            +1 415-287-6069
          </Typography>
        </div>
        <Grid
          className={clsx(classes.marginTop, classes.dates)}
          container
          justify='space-between'
        >
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Due date
            </Typography>
            <Typography>
              {charge.createdAt}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Date of issue
            </Typography>
            <Typography>
            {charge.createdAt}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Status
            </Typography>
            <Typography>
              {`${charge.status.charAt(0).toUpperCase() + charge.status.slice(1)} on ${charge.createdAt}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Billing Cycle
            </Typography>
            <Typography>
              {/* {invoice.periodStart} - {invoice.periodEnd} */}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component='h4'
              gutterBottom
              variant='overline'
            >
              Invoice Number
            </Typography>
            <Typography>
              {charge.id}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.marginTop}>
          <Typography
            component='h4'
            gutterBottom
            variant='overline'
          >
            Billed to
          </Typography>
          <Typography>
            {charge.user && charge.user.firstname ? charge.user.firstname : ""} {charge.user && charge.user.lastname ? charge.user.lastname : ""}<br />
            {charge.user && charge.user.email ? charge.user.email : ""}
          </Typography>
        </div>
        <Table className={classes.marginTop}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              {/* <TableCell>Status</TableCell> */}
              <TableCell align='center'>Media Duration</TableCell>
              <TableCell align='center'>Cost per minute</TableCell>
              <TableCell align='center'>Service</TableCell>
              <TableCell align='center'>Payment Method</TableCell>
              <TableCell align='center'>Credits</TableCell>
              <TableCell align='right'>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charge.jobs ? charge.jobs.map((item, index) => (
              <>
                
                <TableRow key={item._id}>
                  <TableCell size='small' style={{ verticalAlign: 'bottom'}}>
                    {index === 0 ?
                      <>
                      <br />
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                      >
                      TRANSCRIPTION CHARGES
                      </Typography><br />
                      </>
                    :''}

                    <Typography
                      component='h6'
                      variant='h6'
                    >
                    {item.createdAt}
                    </Typography>
                    {item.description }
                  </TableCell>
                  {/* <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom', textTransform: 'capitalize' }} > 
                    <Typography gutterBottom > 
                      <span style={{fontWeight: 'bold'}} > {item.status}</span>
                    </Typography> 
                  </TableCell> */}

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.audioLength ? Math.ceil(item.audioLength/60) + ' min' : ''}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.creditsUsed ? "$0.04/min" : `${item.audioLength ? '$0.08/min' : ''}`}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.serviceName}
                    </Typography> 
                  </TableCell>

                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom', textTransform: 'capitalize'  }} > 
                    <Typography gutterBottom > 
                      
                      {item.creditsUsed ? 'Pre-paid Minutes' : ''}
                      {item.creditsUsed && item.cost > 0 ? '/' : ''}
                      {item.cost > 0 ? 'Credit Card' : ''}
                    </Typography> 
                  </TableCell>
                  <TableCell colSpan={1} align='center' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                      {item.creditsUsed ? item.creditsUsed : ''}
                    </Typography> 
                  </TableCell>
                  <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                    ${item.cost}
                    {/* {item.amount > 0 && item.creditsUsed > 0 ? 
                        `$${Math.abs((item.cost-item.creditsUsed).toFixed(2))}`
                      :
                       `$${(item.amount/100).toFixed(2)}`
                    } */}
                    {/* {`$${(item.amount/100).toFixed(2)}`} */}
                    </Typography> 
                  </TableCell>
                  
                </TableRow>
              </>
            )) : ''}

            {/* {invoice.invoiceItems.filter(item => item.description.indexOf('1 × Transcriptive') > -1 ).map((item, index) => (
              <>
                <TableRow key={item._id}>
                  <TableCell size='small' style={{ verticalAlign: 'bottom'}}>
                      <br />
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                      >
                      SUBSCRIPTION CHARGES
                      </Typography><br />

                    <Typography
                      component='h6'
                      variant='h6'
                    >
                    {item.createdAt}
                    </Typography>
                    {item.description }
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={1} align='right' style={{ verticalAlign: 'bottom' }} > 
                    <Typography gutterBottom > 
                    {`$${(item.amount/100).toFixed(2)}`}
                    </Typography> 
                  </TableCell>
                  
                </TableRow>
              </>
            ))} */}
            {/* <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align='right'><b>Subtotal</b></TableCell>
              <TableCell align='right'>
                $
                {(invoice.subtotal/100).toFixed(2)}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align='right'><b>Total</b></TableCell>
              <TableCell align='right'>
                ${charge.total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
       
        {/* <div className={classes.marginTop}> 
          <Typography
            component='h4'
            gutterBottom
            variant='overline'
          >
            Refunds
          </Typography>
          <Table className={classes.marginTop}>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions && transactions.map(item => (
                <TableRow key={item._id}>
                  <TableCell>
                    ${(item.amount/100).toFixed(2)}
                  </TableCell>
                  <TableCell>{moment(invoice.date).format('LLL')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div> */}
      </CardContent>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  charge: PropTypes.object.isRequired
};

export default Details;
