import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import axios from 'utils/axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors
} from '@material-ui/core';
import { Label, GenericMoreButton } from 'components';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  }
}));

const Invoices = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const statusColors = {
    pending: colors.orange[600],
    paid: colors.green[600],
    succeeded: colors.green[600],
    rejected: colors.red[600],
    failed: colors.red[600],
    create: colors.lightGreen[600],
    manual: colors.blueGrey[600],
    refunded: colors.blueGrey[600],
  };

  const renderCreated = invoice => {
    let { created } = invoice;
    return moment.unix(created).format('lll');
  }

  const renderPeriod = invoice => {
    let { period_start, period_end } = invoice;
    if (period_start && period_end) {
      if (period_start === period_end) {
        return `${moment.unix(period_start).format('MMM Do')}`;
      } else {
        return `${moment.unix(period_start).format('MMM Do')} - ${moment.unix(period_end).format('MMM Do')}`;
      }
    } 
  }

  const renderDescription = invoice => {

    let { billingReason } = invoice;
    billingReason = billingReason.split('_').join(' ')
    let element;


    switch(billingReason){
      case 'subscription create':
        element = <Chip color='secondary' style={{ textTransform: 'capitalize', backgroundColor: statusColors.create }} label={billingReason} />
        break;
      case 'subscription cycle':
        element = <Chip color='default' style={{textTransform: 'capitalize'}} label={billingReason} />
        break
      case 'subscription update':
        element = <Chip color='primary' style={{textTransform: 'capitalize'}} label={billingReason} />
        break;
      case 'manual':
        element = <Chip color='secondary' style={{ textTransform: 'capitalize', backgroundColor: statusColors.manual }} label={billingReason} />
        break;
      default:
        element = <Chip style={{textTransform: 'capitalize'}}  label={billingReason} />
        break;
    }

    return element
  }

  const renderType = payment => {
    let { type } = payment;
    let element;

    switch (type) {
      case 'trust score limit':
        element = <Chip color='secondary' style={{ textTransform: 'capitalize', backgroundColor: statusColors.create }} label={type} />
        break;
    // case 'subscription cycle':
    //   element = <Chip color='default' style={{textTransform: 'capitalize'}} label={billingReason} />
    //   break
      case 'invoice':
        element = <Chip color='primary' style={{textTransform: 'capitalize'}} label={type} />
        break;
      case 'charge':
        element = <Chip color='secondary' style={{ textTransform: 'capitalize', backgroundColor: statusColors.manual }} label={type} />
        break;
      case 'prepaid minutes':
        element = <Chip color='secondary' style={{ textTransform: 'capitalize', backgroundColor: statusColors.manual }} label={type} />
        break;
      default:
        element = <Chip style={{textTransform: 'capitalize'}}  label={type} />
        break;
    }
    return element
  }

  let myPaddingStyle = {
    paddingTop: 5,
    paddingBottom: 5,
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rest.payments && rest.payments.map(payment => (
                    <TableRow key={payment.id} >
                      <TableCell style={myPaddingStyle}>
                        {payment.refunded ? 
                          <b style={{ color: 'gray' }}>${(payment.amount/100).toFixed(2)}</b>
                        : 
                          <b>${(payment.amount/100).toFixed(2)}</b>
                        }
                      </TableCell>
                      <TableCell style={myPaddingStyle}>
                        {payment.refunded ? 
                            <Label
                              color={statusColors[payment.refunded]}
                              variant='contained'
                          >
                            Refunded
                          </Label>
                        : 
                          <Label
                            color={statusColors[payment.status]}
                            variant='contained'
                        >
                          {payment.status}
                        </Label>
                        }
                      </TableCell>
                      <TableCell style={myPaddingStyle}>{payment.description ? payment.description : payment.id}</TableCell>
                      <TableCell style={myPaddingStyle}>{renderCreated(payment)}</TableCell>
                      <TableCell style={myPaddingStyle}>
                        <Button
                          color='primary'
                          component={RouterLink}
                          size='small'
                          to={`/admin/invoices/${payment.stripeId}`}
                          variant='contained'
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

Invoices.propTypes = {
  className: PropTypes.string
};

export default Invoices;
