import React, { Fragment, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AchiveIcon from '@material-ui/icons/ArchiveOutlined';

const useStyles = makeStyles(() => ({}));

const FilterStatsButton = props => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(100);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (event, index) => {
    props.onFilter(index)
    setSelectedIndex(index);
    setOpenMenu(false);
  };

  return (
    <Fragment>
      <Tooltip title='More options'>
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size='small'
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={event => handleMenuItemClick(event, 100)}
          selected={selectedIndex === 100}
        >
          <ListItemText primary='30 Days Ago' />
        </MenuItem>
        <MenuItem
          onClick={event => handleMenuItemClick(event, 200)}
          selected={selectedIndex === 200}
        >
          <ListItemText primary='3 Months Ago' />
        </MenuItem>
        <MenuItem
          onClick={event => handleMenuItemClick(event, 300)}
          selected={selectedIndex === 300}
        >
          <ListItemText primary='6 Months Ago' />
        </MenuItem>
        <MenuItem
          onClick={event => handleMenuItemClick(event, 400)}
          selected={selectedIndex === 400}
        >
          <ListItemText primary='12 Months Ago' />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

FilterStatsButton.propTypes = {
  className: PropTypes.string
};

export default memo(FilterStatsButton);
