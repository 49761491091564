import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AddCardForm from './forms/AddCardForm'
import CardTable from './tables/CardTable'
import axios from 'utils/axios';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  progressWrapper: {
    textAlign: 'center'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

const PaymentMethods = props => {
  const { className, user, ...rest } = props;
  const classes = useStyles();

  const [ cards, setCards ] = useState([])
  const [showLoading, setShowLoading] = useState(true);
  const apiUrl = '/v1/card';

  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState('bottom');
  const [horizontal, setHorizontal] = useState('center');
  const [snackMessage, setSnackMessage] = useState('');

	useEffect(() => {
    let mounted = true;
    const fetchCards = () => {
      axios.get(apiUrl).then(response => {
        if (mounted) {
          setCards(response.data.cards);
          setShowLoading(false)
        }
      });
    };
    fetchCards();
    return () => {
      mounted = false;
    };
  }, []);

  // if (!cards.length > 0) {
  //   return null;
  // }

  const addCard = data => {
    setShowLoading(true);
    data.userId = user._id;
    axios.post(apiUrl, data)
      .then((result) => {
        let msg = 'Card successfully added!';
        if (result.data.error && result.data.error.match(/No such token/)) {
          msg = 'Error adding new card, please try again';
          setShowLoading(false);
          setSnackMessage(msg);
          setOpen(true);
        } else if (result.data.error) {
          setShowLoading(false);
          setSnackMessage(result.data.error);
          setOpen(true);
        } else {
          const { cards } = result.data;
          setCards(cards);
          setShowLoading(false);
          setSnackMessage(msg)
          setOpen(true);
        }
      })
      .catch((error) => setShowLoading(false));
  }

  const deleteCard = data => {
    setShowLoading(true);
    axios.delete(apiUrl, { params: data })
      .then(result => {
	      const { cardId, default: defaultId } = result.data;
        
        // filter out the old card
        let newCards = cards.filter(card => card._id !== cardId);
        
        // assign the default
        newCards = newCards.map(item => {
          if(item._id === defaultId){
            return { ...item, default: true };
          } else {
            return item;
          }
        });

	      setCards(newCards);
        setShowLoading(false);
        setSnackMessage('Card successfully removed.')
        setOpen(true);
      })
      .catch((error) => setShowLoading(false));
  }

  const defaultCard = data => {
    setShowLoading(true);
    axios.post(`${apiUrl}/default`, data)
      .then(result => {
        const { cards } = result.data;
        setCards(cards);
        setShowLoading(false);
        setOpen(true);
      })
      .catch((error) => setShowLoading(false));
  }

  const handleClose = () => { setOpen(false) }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        classes={{ action: classes.action }}
        title='Manage your payment methods'
      />
      <Divider />
      <CardContent>
        <Card>
          <CardContent className={classes.overview}>
            <AddCardForm addCard={addCard} />
          </CardContent>
          <Divider />
          {showLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} />
            </div>
            :
            <CardContent className={classes.details}>
              <CardTable cards={cards} deleteCard={deleteCard} defaultCard={defaultCard} />
            </CardContent>
          }
        </Card>
      </CardContent>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={2000}
        transitionDuration={500}
        message={<span id='message-id'>{snackMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Card>
  );
};

PaymentMethods.propTypes = {
  className: PropTypes.string
};

export default PaymentMethods;
