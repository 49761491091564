import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Link
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const JobInfo = props => {
  const { order, className, ...rest } = props;

  const classes = useStyles();

  const options = ['Canceled', 'Completed', 'Rejected'];

  const [option, setOption] = useState(options[0]);

  const user = JSON.parse(localStorage.getItem('user'));

  const handleChange = event => {
    event.persist();
    setOption(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title='Job info' />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>

          {user && user.role === 'user' ?
              <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/admin/customers/${order.userId._id}`}
                >
                  {order.userId && order.userId.firstname ? order.userId.firstname : '' }
                  {order.userId && order.userId.lastname ? order.userId.lastname : '' }
                </Link>
              </TableCell>
            </TableRow>
            :''  }
            
            

            <TableRow selected>
              <TableCell>Date</TableCell>
              <TableCell>
                {moment(order.createdAt).format('DD/MM/YYYY HH:MM a')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{order._id}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Status</TableCell>
              <TableCell>{order.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>
                {order.serviceId && order.serviceId.namePublic}
              </TableCell>
            </TableRow>

            {user && user.role === 'admin' ?
              <TableRow selected>
                <TableCell>Service Cost</TableCell>
                <TableCell>${order.serviceCost && order.serviceCost.toFixed(2)}</TableCell>
              </TableRow>
            :''  }

            <TableRow>
              <TableCell>Duration</TableCell>
              <TableCell>
                {(order.audioLength/60).toFixed(2)} min
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Minutes Charged</TableCell>
              <TableCell>
                {Math.ceil(order.audioLength/60)} min
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{order.userId && order.userId.role === 'admin' ? 'Customer' : ''} Cost</TableCell>
              <TableCell>${order.cost && order.cost.toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Credits</TableCell>
              <TableCell>${order.creditsUsed ? order.creditsUsed / 100 : 0}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell>Prepaid minutes </TableCell>
                <TableCell>{order.minutesUsed ? order.minutesUsed : 0} min</TableCell>
              </TableRow>

            <TableRow selected>
              <TableCell>Project</TableCell>
              <TableCell>{order.projectId && order.projectId.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>{order.options && order.options.lang}</TableCell>
            </TableRow>

            <TableRow selected>
              <TableCell>Video/Audio Uploaded</TableCell>
              <TableCell>{order.name}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

JobInfo.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default JobInfo;
