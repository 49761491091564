import React from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import Confirm from "../forms/Confirm"
import moment from 'moment';

const CardTable = props => {
  const handleDelete = data => event => {
    const { cardId } = data;
    props.deleteCard({cardId})
  }

  const handleDefault = data => event => {
    const { cardId } = data;
    props.defaultCard({cardId})
  }

  const handleChangePlan = data => event => {
    const { priceId } = data;
    const { _id } = props.user;

    props.changePlan({ id: _id, priceId})
  }
  return (
    <Table>
      <TableBody>
        {props.data.length > 0
          ?
            props.data.map(row => (
              <TableRow hover key={row.id}>
                <TableCell>{row.nickname}</TableCell>
                <TableCell><strong>${(row.unit_amount/100).toFixed(2)}</strong></TableCell>
                <TableCell>
                  <Confirm title='Confirm' description='Are you sure?'>
                    {confirm => (
                      <>
                        <Button
                          size='small'
                          variant='contained'
                          onClick={confirm(handleChangePlan({priceId: row.id }))}
                          disabled={props.user && props.user.subscription && props.user.subscription.priceId === row.id}
                          color={props.user && props.user.subscription && props.user.subscription.priceId !== row.id ? 'primary' : ''}
                        >
                          {props.user && props.user.subscription && props.user.subscription.priceId === row.id ? 'Current' : 'Change Plan'}
                        </Button>
                      </>
                    )}
                  </Confirm>
                </TableCell>
              </TableRow>
            ))
          :
            <TableRow><TableCell>No Data</TableCell></TableRow>
        }
      </TableBody>
    </Table>
  )
}

export default CardTable;
